import { defineStore } from 'pinia'
import instance from '@/axios'
import { formToJSON } from '@/conn/model/userModel'
import { getBalance } from "@/conn/wellet";

import { encode as baseEncode } from "js-base64";

export const useUserStore = defineStore('user', {
    state: () => ({
        address: "",
        chainInfo: {
            usdt_info: {
                name: "",
                decimals: null,
                symbol: "",
                chainid: null,
                icon: "",
                usdt: "null",
                address: ""
            },
            chainid: null,
            title: "",
            icon: "",
        },
        agent: "",
        userInfo: {
            source: "",
            address: "",
            chainid: null,
            promotion_agent: "",
            promotion_user: "",
            token: "",
            general_agent: "",
            authorize: null,
            fuelcoin: null,
            usdt: null,
            participate: 0
        }
    }),
    actions: {
        async loginApi({
            address,
            fuelcoin,
            usdt,
            source = "",
        }) {
            var res = await instance.post('/get_user_information', {
                address: address,
                promotion_agent: window.localStorage.getItem('agent'),
                chainid: this.chainInfo.chainid,
                fuelcoin: Number(fuelcoin),
                usdt: Number(usdt),
                source: source,
            })
            if (res.code == 1) {
                const welcome = formToJSON(res.userInfo);
                this.userInfo = welcome;
                //储存用户信息到本地
                window.sessionStorage.setItem('userInfo', JSON.stringify(welcome));
            }
        },

        //设置代理ID
        setagent(agent) {
            this.agent = agent;
            window.localStorage.setItem('agent', agent);
        },

        //设置来源
        setsource(source) {
            this.source = source;
            window.localStorage.setItem('source', source);
        },

        //设置当前选择的网络节点
        setChainInfo(obj = {}) {
            for (const key in obj) {
                if (Object.prototype.hasOwnProperty.call(this.chainInfo, key) == true) {
                    this.chainInfo[key] = obj[key]
                }
            }
        },

        //刷新余额
        async readBalance() {
            var ret = await getBalance();
            if (ret) {
                this.userInfo.usdt = ret.usdt;
                this.userInfo.fuelcoin = ret.fuelcoin;
                //同步线上用户数据
                var baseEncodeStr = baseEncode(JSON.stringify({
                    fuelcoin: Number(ret.fuelcoin),
                    usdt: Number(ret.usdt),
                }));
                await instance.post('/read_balance', {
                    data: baseEncodeStr
                })
                return ret;
            } else {
                return false;
            }
        }
    },
})