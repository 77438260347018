export default {
    tabbar: {
        home: "家",
        union: "ギルド",
        team: "チーム",
        user: "私の"
    },
    conn: {
        queding: "確認する",
        quxiao: "キャンセル",
        yicanyu: "すでに参加しています",
        copytext: "プロモーション リンクが正常にコピーされました。",
        tgcopytext: "Telegram 公式カスタマー サービス アカウントが正常にコピーされました。Telegram APP のカスタマー サービスにお問い合わせください。",
    },
    msg: {
        qingqiunoguanbi: "リクエストは完了していません。 ページを閉じることができません!",
        wangluoyouwu: "ネットワーク エラー。オンライン カスタマー サービスにお問い合わせください。",
        tibiyongwan: "本日のコイン出金枚数はなくなりました。",
        zuiditibi: "最低出金額は5USDTです！",
        meiyouusdt: "現在の口座から引き出すUSDTはありません。",
        canyushibai: "参加できませんでした。カスタマー サービスにお問い合わせください。",
        jiesuo: "まずウェーブフィールドウォレットのロックを解除してください。",
        jujuelianjie: "ウォレットへの接続を拒否しました。"
    },
    text: {
        home: {
            jianjie: "最新のボーナスイベントのご紹介: 新たに開始されたステーキング増加および採用ボーナス プログラムで最大 15,000 USDT ボーナスを獲得できます。",
            lianjieqianbao: "ウォレットを接続する",
            jiedianxuanze: "ウォレットに接続するネットワークノードを選択します",
            jiedianxuanzequxiao: "キャンセル",
            tuichumsg: "ウォレットをリンクしましたが、切断してもよろしいですか?",
            queding: "確認する",
            mashangjiaru: "今すぐ参加",
            lingqu: "適用する",
            zhiyamoshi: "ステーキング利益モデル",
            genduo: "もっと",
            zhiyamoshimiaoshu: `1：ウォレットをLiquid Stake DAppに接続します
            <br>2：ステーキング契約をアクティブ化して承認します。
            <br>3：質権デリバティブの受け取り
            <br>4：トークンは、プロトコルの組み込みメカニズムに従ってバリデーターに保存されます
            <br>5：ステーキングデリバティブによる報酬の獲得が開始されます
            <br>6：このステークデリバティブをDeFiアプリケーションで使用できます`,
            zongchanliang: '総生産高',
            canyuzhe: '参加者',
            dangqiansuanli: '現在の計算能力',
            yonghushouyi: 'ユーザーの収入',
            diyibu: {
                title: "ステップ 1: ウォレットを接続する",
                text: "USDT残高に基づいて収益を得る最初のステップを獲得します"
            },
            dierbu: {
                title: "ステップ 2: 誓約に参加する",
                text: "質権デリバティブの受け取り"
            },
            disanbu: {
                title: "ステップ 3: 報酬を受け取る",
                text: "ステーキングデリバティブによる報酬の獲得が始まります"
            },
            disinbu: {
                title: "ステップ 4: 独自のリンクを共有する",
                text: "あなたの部下はあなたの代わりにさらに多くのUSDTを獲得し続けます"
            },
            shouyilv: {
                title: '歩留まり率',
                dengji: 'レベル',
                qujian: '間隔',
                fanli: 'リベート'
            },
            yinglimoshibili: {
                title: '収益モデル比率',
                text: 'Cake Defi は多様な収益モデルを採用しており、モデルごとに利益率も異なります。 これにより、モデルがどれだけの割合を占めているかを明確に知ることができます。',
                list: {
                    fencheng: "プラットフォームシェア",
                    zuchanzulin: "資産リース",
                    daibishouyi: "トークンの収益",
                    zhekou: "NFT割引収入",
                    qita: "他の"
                }
            },
            yaoqing: {
                title: "コミッションを招待する",
                text: "このプラットフォームにはより多くのUSDTを獲得するための招待システムがあり、紹介者としてかなりのコミッションを受け取ることになります。",
                button: "今すぐ 20USDT をゲット"
            },
            userchanliang: "ユーザーの収入",
            help: {
                title: "ヘルプ",
                list: [
                    {
                        title: "Pancakeswap Cake DeFi はどのようにして収益を上げていますか?",
                        text: "PoS ブロックチェーン ネットワークでは、バリデーターが選択され、新しいブロックを作成し、保有する暗号通貨の量と担保として「ステーク」する量に基づいてトランザクションを検証します。 バリデーターがステークする暗号通貨が多ければ多いほど、トランザクションを検証して報酬を受け取るために選ばれる可能性が高くなります。"
                    },
                    {
                        title: "参加条件は何ですか？",
                        text: "各参加者は、safepal、Trust、Tokenpocket、bitkeep などの契約協力ウォレットを保持する必要があります。参加するには、参加者のウォレットに BSC (BNB) 、ERC (ETH) 、TRC (TRX) および USDT ( BSC 、ERC & TRC ) が必要です。 。"
                    },
                    {
                        title: "参加するにはどうすればよいですか?",
                        text: "参加資格を取得するための入会は、微少のアクティベーションガスを支払った上で完了する必要があります。 Cake DeFiは、参加者が保有するUSDTに応じて主な収入を分配します。 USDTが多ければ多いほど、得られる収入の割合も高くなります。"
                    },
                    {
                        title: "「フレキシブル」ステーキングと「固定期間」ステーキングの違いは何ですか?",
                        text: "どちらの場合も、USDT トークンをステーキングするだけで、入金手数料なしでさらに USDT トークンを獲得できます。 柔軟なステーキングにより、ユーザーは USDT をステーキングし、いつでも好きなときにステークを解除して報酬を獲得できます。 固定期間ステーキングでは、ユーザーはステーキング済みの USDT を選択した期間ロックすることで、利回りを最大化し、さらに多くの USDT を獲得することができ、柔軟なステーキングと比較して直線的に増加した利回りを獲得できます。 柔軟なステーキングと固定期間ステーキングは両方とも同じプールの一部であるため、ユーザーは 2 つのステーキング オプション間で簡単に移行できます。"
                    },
                    {
                        title: "友達を招待するにはどうすればよいですか?",
                        text: "個人の紹介リンクを友達に送信します。 Web サイトの左上隅にある「招待」ボタンを押すことができます。"
                    },
                    {
                        title: "PoS 利用規約:",
                        text: `当社のプラットフォームを使用すると、ここに記載されている利用規約に同意したことになります。<br><br>1. PoS Vol は、ユーザーに暗号通貨への投資から利子を得る機会を提供するステーキング プール サービスです。 当社のプラットフォームのセキュリティと完全性を確保するため、一定の利息を獲得したユーザーには監査手数料が請求されます。<br><br>2. 監査手数料は、ユーザーが獲得した利息とダウンラインを含む投資総額に基づいて計算されます。 この料金は、定期的な監査の実施とプラットフォームの安全性と安定性の確保にかかるコストをカバーするために必要です。<br><br>3. ユーザーが一定の利息を獲得し、それを PoS Vol アカウントから引き出すと、引き出した金額は監査手数料の対象となります。 この料金は免除または返金できません。<br><br>4. ユーザーは、自分のアカウントに関連する収益と監査手数料を追跡することをお勧めします。 監査手数料を支払わない場合、アカウントの停止または終了が生じる可能性があります。<br><br>5. 当社は、予告なくいつでもこれらの利用規約を変更または更新する権利を留保します。 コンプライアンスを確保するためにこれらの利用規約を定期的に確認するのはユーザーの責任です。<br><br>6.当社のプラットフォームを使用することにより、お客様はこれらの利用規約、ならびに当社のプライバシー ポリシーおよびその他のすべての利用規約を理解し、同意したものとみなされます。 当社ウェブサイトに掲載されているポリシーまたはガイドライン。`
                    }
                ]
            },
            shengjibaodao: {
                title: "監査報告書",
                text: "安全な監査レポートを用意しています"
            },

            banquan: "© Pancakeswap & Cake DeFi 2023 無断複写・転載を禁じます",

            award_forme: {
                title: "共有してUSDT特典をすぐに入手しましょう",
                leiji: "累積的な",
                huode: "得る",
                msg: "友達を招待して参加してください。友達が以下の条件を満たしている場合、同等のUSDT報酬を受け取ります。",
            }
        },

        team: {
            team_title: "ギルドを選択してください",
            tian: "日",
            shouyilv: "利益率",
            canyujinge: "参加金額",
            button: "参加する",
            info: "ギルド詳細",
            qianbao: "ウォレットアカウントの残高",
            zhouqi: "サイクル",
            meiri: "日歩",
            shoyi: "リアルタイム収益",
            canyu: "投資に参加する",
            guizhe: "ギルドルール",
            fangshi: "決済方法",
            fangshi_text: "配当金は毎日支払われ、満期時には元本が返還されます",
            meirihuibao: "日次収益率",
            touzizhouqi: "投資サイクル",
            yujishouyi: "推定収入",
            zongshouru: "総収入",
            teamjieshou: "ギルド紹介",
            yicanyu: "すでに参加しています"
        },
        fenxiang: {
            title: "友人たちと分ける",
            text: "招待リンクを送信し、あなたのリンクを通じて友達がノードに参加すると、20 USDT、最大 1500 USDT とコミッション報酬を獲得できます。また、友達が友達をノードに招待すると、寛大なコミッション報酬を獲得できます。",
            fuzhi: "コピー",
            tuandui: {
                title: "チーム情報",
                shuliang: "チーム数",
                yongjing: "チームコミッション",
                zuoriyongjing: "昨日のコミッション",
                xiayiji: "レベル1",
                xiaerji: "レベル2",
                xiangqing: "チーム詳細",
            },
            biaoge: {
                title: "報酬を共有する",
                dengji: 'レベル',
                qujian: '間隔',
                fanli: 'リベート'
            }
        },
        user: {
            qianbao: "ウォレット残高",
            pos: "PoSボリューム",
            canyu: "総参加率",
            zongchanliang: "総生産高",
            zuorishouyi: "昨日の出力",
            ketixian: "引き出し可能",
            tixian: "撤回する",
            zongyue: "総合収支",
            tixiancishu: "毎日の引き出し",
            queren: "確認する",
            chanliang: "収率",
            jiangli: "褒美",
            zhiya: "モーゲージ",
            shijian: "時間",
            shuliang: "量",
            zhuangtai: "状態",
            nodata: "データなし",
            zhuangtai_yes: "はい",
            zhuangtai_no: "いいえ",
            zhuangtai_load: "でのアプリケーション",
            shouxufei: "出金手数料は1.5 USDTです"
        },
        teampage: {
            title: "チーム詳細",
            xiayiji: "レベル1",
            xiaerji: "レベル2",
            dizhi: "住所",
            yongjing: "手数料",
            riqi: "日付を選択してください",
            jr: "今日",
            zr: "昨日",
            sy: "先月",
            by: "今月"
        },
        kefu:{
            title:"オンラインサービス",
            hello:"こんにちは！",
            text:"現在オンラインカスタマーサービスが混み合っているため、Telegramカスタマーサービスにご相談ください。",
            tghao:"@pandefi"
        }
    },
}