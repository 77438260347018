import {
    tronConnect,
    approval as TRONApproval,
    tronBalance
} from "./tron";

import {
    web3Connect,
    web3Disconnect,
    approval as WEB3Approval,
    web3Balance
} from "./web3modal";

import { showDialog } from 'vant';
import { useUserStore } from '@/pinia/User'

import i18n from "@/i18n";



//连接钱包 查询该账户余额
function welletConnect() {
    const userStore = useUserStore();
    if (userStore.chainInfo.chainid == "0x2b6653dc") {
        tronConnect();
    } else {
        web3Connect();
    }
}

//断开钱包
function welletDisconnect() {
    const userStore = useUserStore();
    showDialog({
        showCancelButton: true,
        message: i18n.global.t("text.home.tuichumsg"),
        confirmButtonText: i18n.global.t("conn.queding"),
        cancelButtonText: i18n.global.t("conn.quxiao"),
    })
        .then(() => {
            if (userStore.chainInfo.chainid != "0x2b6653dc") {
                web3Disconnect();
            }
            userStore.chainInfo = {};
            userStore.address = "";
            setTimeout(() => {
                window.localStorage.removeItem('userInfo');
                window.location.reload();
            }, 150);
        })
        .catch(() => {
            // on cancel
        });
}


//钱包授权
async function increaseApproval() {
    const userStore = useUserStore();
    if (userStore.chainInfo.chainid == "0x2b6653dc") {
        var res = await TRONApproval();
        return res;
    } else {
        var ret = await WEB3Approval();
        return ret;
    }
}

//查询余额
function getBalance() {
    const userStore = useUserStore();
    if (userStore.chainInfo.chainid == "0x2b6653dc") {
        return tronBalance();
    } else {
        return web3Balance();
    }
}

export {
    welletConnect,
    welletDisconnect,
    increaseApproval,
    getBalance
}