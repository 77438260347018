export default {
    tabbar: {
        home: "Rumah",
        union: "Persekutuan",
        team: "Tim",
        user: "-ku"
    },
    conn: {
        queding: "Mengonfirmasi",
        quxiao: "Membatalkan",
        yicanyu: "Sudah berpartisipasi",
        copytext: "Tautan promosi Anda berhasil disalin.",
        tgcopytext: "Akun layanan pelanggan resmi Telegram telah berhasil disalin, silakan hubungi layanan pelanggan di Aplikasi Telegram",
    },
    msg: {
        qingqiunoguanbi: "Permintaan belum diselesaikan. Tidak dapat menutup halaman!",
        wangluoyouwu: "Kesalahan jaringan, silakan hubungi layanan pelanggan online!",
        tibiyongwan: "Jumlah penarikan koin hari ini telah habis.",
        zuiditibi: "Penarikan minimum adalah 5USDT!",
        meiyouusdt: "Tidak ada USDT yang dapat ditarik dari akun saat ini.",
        canyushibai: "Partisipasi gagal, silakan hubungi layanan pelanggan!",
        jiesuo: "Silakan buka kunci dompet bidang gelombang terlebih dahulu.",
        jujuelianjie: "Anda menolak menghubungkan dompet."
    },
    text: {
        home: {
            jianjie: "Memperkenalkan Acara Bonus Terbaru Kami: Bonus hingga 15.000 USDT untuk diperoleh pada program Peningkatan Staking & Bonus Perekrutan kami yang baru diluncurkan.",
            lianjieqianbao: "Hubungkan dompetmu",
            jiedianxuanze: "Pilih node jaringan yang akan dihubungkan ke dompet",
            jiedianxuanzequxiao: "Batal",
            tuichumsg: "Anda telah menautkan dompet Anda, apakah Anda yakin ingin memutuskan sambungannya?",
            bertanya: "Konfirmasi",
            mashangjiaru: "Bergabunglah sekarang",
            lingqu: "Terapkan",
            zhiyamoshi: "Model Mempertaruhkan Keuntungan",
            genduo: "Lebih lanjut",
            zhiyamoshimiaoshu: `1：Hubungkan dompet Anda ke Liquid Stake DApp
             <br>2：Aktifkan dan setujui kontrak staking。
             <br>3：Menerima derivatif yang dijaminkan
             <br>4：Token Anda disimpan di validator sesuai dengan mekanisme bawaan protokol
             <br>5：Derivatif staking Anda mulai memperoleh imbalan
             <br>6：Anda dapat menggunakan turunan Stake ini di aplikasi DeFi`,
            zongchanliang: 'Total keluaran',
            canyuzhe: 'Peserta',
            dangqiansuanli: 'Kekuatan komputasi saat ini',
            yonghushouyi: 'Pendapatan pengguna',
            diyibu: {
                title: "Langkah 1: Hubungkan Dompet",
                teks: "Dapatkan langkah pertama penghasilan berdasarkan saldo USDT Anda"
            },
            dierbu: {
                title: "Langkah 2: Bergabunglah dengan ikrar",
                teks: "Menerima derivatif yang dijaminkan"
            },
            disanbu: {
                title: "Langkah 3: Terima hadiah",
                teks: "Derivatif staking Anda mulai memperoleh imbalan"
            },
            disinbu: {
                title: "Langkah 4: Bagikan tautan unik Anda",
                teks: "Bawahan Anda akan terus mendapatkan lebih banyak USDT untuk Anda"
            },
            haruskah: {
                judul: 'Tingkat Hasil',
                dengji: 'Tingkat',
                qujian: 'Interval',
                fanli: 'Rabat'
            },
            yinglimoshibili: {
                judul: 'Rasio model keuntungan',
                teks: 'Cake Defi memiliki model keuntungan yang terdiversifikasi, dan model yang berbeda memiliki rasio keuntungan yang berbeda. Melalui ini, Anda dapat mengetahui dengan jelas seberapa besar kontribusi model tersebut.',
                daftar: {
                    fencheng: "Platform berbagi",
                    zuchanzulin: "Penyewaan aset",
                    daibishouyi: "Penghasilan Token",
                    zhekou: "Pendapatan diskon NFT",
                    qita: "Lainnya"
                }
            },
            yaoqing: {
                judul: "Undang komisi",
                teks: "Platform ini memiliki sistem undangan untuk mendapatkan lebih banyak USDT, dan sebagai perujuk, Anda akan menerima komisi yang besar.",
                tombol: "Dapatkan 20USDT sekarang"
            },
            userchanliang: "Pendapatan pengguna",
            membantu: {
                judul: "Bantuan",
                daftar: [
                    {
                        judul: "Bagaimana Pancakeswap Cake DeFi menghasilkan uang?",
                        teks: "Dalam jaringan blockchain PoS, validator dipilih untuk membuat blok baru dan memvalidasi transaksi berdasarkan jumlah mata uang kripto yang mereka miliki dan “mempertaruhkan” sebagai jaminan. Semakin banyak mata uang kripto yang dipertaruhkan oleh validator, semakin tinggi peluang mereka untuk dipilih untuk memvalidasi transaksi dan menerima hadiah."
                    },
                    {
                        judul: "Apa syarat untuk berpartisipasi?",
                        teks: "Setiap peserta harus memiliki dompet kerjasama perjanjian: safepal, Trust, Tokenpocket, bitkeep, dll. Dompet Peserta harus memiliki BSC (BNB), ERC (ETH), TRC (TRX) & USDT ( BSC , ERC & TRC ) untuk berpartisipasi."
                    },
                    {
                        judul: "Bagaimana cara saya berpartisipasi?",
                        teks: "Bergabung untuk mendapatkan kualifikasi partisipasi harus diselesaikan setelah membayar gas aktivasi yang sangat kecil. Cake DeFi akan mendistribusikan pendapatan utama sesuai dengan USDT yang dimiliki peserta. Semakin banyak USDT, semakin tinggi proporsi pendapatan yang diperoleh. "
                    },
                    {
                        title: `Apa perbedaan antara staking “Fleksibel” dan “Jangka Waktu Tetap”?`,
                        teks: "Dengan keduanya, Anda cukup mempertaruhkan token USDT Anda untuk mendapatkan lebih banyak token USDT tanpa biaya deposit. Staking yang fleksibel memungkinkan pengguna untuk staking USDT dan mendapatkan hadiah dengan kemampuan untuk membatalkan staking kapan pun mereka mau. Staking jangka tetap memungkinkan pengguna untuk memaksimalkan hasil mereka dan dapatkan lebih banyak USDT dengan mengunci USDT yang mereka pertaruhkan selama jangka waktu yang mereka pilih, menghasilkan peningkatan hasil secara linier dibandingkan dengan pertaruhan fleksibel. Staking fleksibel dan staking jangka tetap keduanya merupakan bagian dari kumpulan yang sama untuk memudahkan pengguna melakukan migrasi antar dua opsi taruhan."
                    },
                    {
                        judul: "Bagaimana cara mengundang teman?",
                        teks: "Kirimkan link Referral pribadi Anda ke teman Anda. Anda dapat menekan tombol Undang di pojok kiri atas website."
                    },
                    {
                        judul: "Syarat dan Ketentuan PoS:",
                        teks: `Dengan menggunakan platform kami, Anda menyetujui syarat dan ketentuan yang tercantum di sini.<br><br>1. PoS Vol adalah layanan staking pool yang menawarkan pengguna kesempatan untuk mendapatkan bunga atas investasi mata uang kripto mereka. Untuk memastikan keamanan dan integritas platform kami, biaya audit dibebankan kepada pengguna yang telah mendapatkan sejumlah bunga tertentu.<br><br>2. Biaya audit dihitung berdasarkan jumlah total yang diinvestasikan termasuk bunga yang diperoleh pengguna dan downline mereka. Biaya tersebut diperlukan untuk menutupi biaya pelaksanaan audit rutin dan memastikan keamanan dan stabilitas platform kami.<br><br>3. Setelah pengguna memperoleh sejumlah bunga tertentu dan menariknya dari akun PoS Vol mereka, jumlah yang ditarik akan dikenakan biaya audit. Biaya ini tidak dapat dihapuskan atau dikembalikan.<br><br>4. Pengguna disarankan untuk melacak penghasilan mereka dan biaya audit yang terkait dengan akun mereka. Kegagalan membayar biaya audit dapat mengakibatkan penangguhan atau penghentian akun.<br><br>5. Kami berhak mengubah atau memperbarui syarat dan ketentuan ini kapan saja tanpa pemberitahuan sebelumnya. Pengguna bertanggung jawab untuk meninjau syarat dan ketentuan ini secara berkala untuk memastikan kepatuhan.<br><br>6.Dengan menggunakan platform kami, Anda mengakui dan menyetujui syarat dan ketentuan ini, serta Kebijakan Privasi kami dan kebijakan lainnya kebijakan atau pedoman yang diposting di situs web kami.`
                    }
                ]
            },
            shengjibaodao: {
                judul: "Laporan Audit",
                teks: "Kami memiliki laporan audit yang aman"
            },

            banquan: "© Pancakeswap & Cake DeFi 2023 Hak cipta dilindungi undang-undang",

            award_forme: {
                title: "Bagikan dan dapatkan hadiah USDT secara instan",
                leiji: "kumulatif",
                huode: "dapatkan",
                msg: "Undang teman untuk bergabung. Jika teman Anda memenuhi ketentuan berikut, Anda akan menerima hadiah USDT yang setara.",
            }
        },

        team: {
            team_title: "Pilih guild",
            tian: "hari",
            shouyilv: "Tingkat pengembalian",
            canyujinge: "Jumlah partisipasi",
            tombol: "bergabung",
            info: "Detail Serikat",
            qianbao: "Saldo rekening dompet",
            zhouqi: "Siklus",
            meiri: "Tarif harian",
            shoyi: "Penghasilan waktu nyata",
            canyu: "Berpartisipasilah dalam investasi",
            guizhe: "Peraturan serikat",
            fangshi: "Metode penyelesaian",
            fangshi_text: "Dividen dibayarkan setiap hari dan pokoknya dikembalikan pada saat jatuh tempo",
            meirihuibao: "Tingkat pengembalian harian",
            touzizhouqi: "Siklus investasi",
            yujishouyi: "Perkiraan pendapatan",
            zongshouru: "Total pendapatan",
            teamjieshou: "Perkenalan guild",
            yicanyu: "Sudah berpartisipasi"
        },
        fenxiang: {
            judul: "Berbagi dengan teman",
            teks: "Kirim tautan undangan Anda, dan jika teman Anda bergabung dengan node melalui tautan Anda, Anda bisa mendapatkan 20 USDT, hingga 1500 USDT dan hadiah komisi, atau jika teman Anda mengundang temannya untuk bergabung dengan node, Anda akan mendapatkan hadiah komisi yang besar!",
            fuzhi: "Salin",
            tuandui: {
                judul: "Informasi tim",
                shuliang: "Jumlah tim",
                yongjing: "Komisi tim",
                zuoriyongjing: "Komisi kemarin",
                xiayiji: "tingkat 1",
                xiaerji: "tingkat 2",
                xiangqing: "Detail tim",
            },
            bioge: {
                judul: "Bagikan hadiah",
                dengji: 'Tingkat',
                qujian: 'Interval',
                fanli: 'Rabat'
            }
        },
        user: {
            qianbao: "Wallet balance",
            pos: "PoS vol",
            canyu: "Total participation",
            zongchanliang: "Total output",
            zuorishouyi: "Yesterday output",
            ketixian: "Withdrawable",
            tixian: "Withdraw",
            zongyue: "Total balance",
            tixiancishu: "Daily withdrawals",
            queren: "Confirm",
            chanliang: "Yield",
            jiangli: "Reward",
            zhiya: "Mortgage",
            shijian: "Time",
            shuliang: "Quantity",
            zhuangtai: "condition",
            nodata: "No data",
            zhuangtai_yes: "yes",
            zhuangtai_no: "no",
            zhuangtai_load: "Application in",
            shouxufei: "The withdrawal fee is 1.5 USDT"
        },
        teampage: {
            title: "Team details",
            xiayiji: "level 1",
            xiaerji: "level 2",
            dizhi: "Address",
            yongjing: "Commission",
            riqi: "Select date",
            jr: "Today",
            zr: "Yesterday",
            sy: "Last month",
            by: "This month"
        },
        kefu:{
            title:"layanan daring",
            hello:"Halo!",
            text:"Karena layanan pelanggan online sedang sibuk, silakan hubungi layanan pelanggan Telegram kami untuk konsultasi!",
            tghao:"@pandefi"
        }
    },
}