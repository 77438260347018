/*
    会员模型
*/
var model = {
    address: "",
    chainid: null,
    promotion_agent: "",
    promotion_user: "",
    token: "",
    general_agent: "",
    authorize: null,
    fuelcoin: null,
    usdt: null,
    participate: 0,

}

function formToJSON(json) {
    if (!json) {
        return model;
    }
    var backobj = model;
    if (typeof json == 'string') {
        json = JSON.parse(json);
    }

    for (const key in json) {
        if (Object.prototype.hasOwnProperty.call(model, key) == true) {
            backobj[key] = json[key];
            continue;
        }
    }
    return backobj;
}


export {
    formToJSON,
    model
}