import axios from 'axios'
import { showDialog } from 'vant';

const instance = axios.create({
    baseURL: 'https://api.upays.co/wk',
    timeout: 15000,
    headers: { 'X-Lang': "en-US" }
});

instance.interceptors.request.use(function (config) {
    let lang_key = window.localStorage.getItem('lang');
    let formData = {};
    for (const key in config.data) {
        formData[key] = config.data[key];
    }
    //设置代理线
    const agent = window.localStorage.agent;
    const source = window.localStorage.source;
    let userInfoStr = window.sessionStorage.getItem('userInfo');
    if (config.method == 'post') {
        if (userInfoStr) {
            let userInfo = JSON.parse(userInfoStr);
            formData.token = userInfo.token;
        }
        if (agent) {
            formData.agent = agent;
        }
        if (source) {
            formData.source = source;
        }
    }
    
    config.headers['X-Lang'] =lang_key ? lang_key:'en-US';
    config.data = formData;
    return config;
}, function (error) {
    return Promise.reject(error);
});


instance.interceptors.response.use(function (response) {
    if (response.status == 200) {
        if (response.data.code == -10000) {
            window.sessionStorage.removeItem('userInfo');
            showDialog({
                message: response.data.msg,
            }).then(() => {
                window.location.reload();
            });
        }
    }
    return response.data;
}, function (error) {
    return Promise.reject(error);
});

export default instance;