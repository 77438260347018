export default {
    tabbar: {
        home: "Home",
        union: "Guild",
        team: "Team",
        user: "My"
    },
    conn: {
        queding: "Confirm",
        quxiao: "Cancel",
        yicanyu: "Already participated",
        copytext: "Your promotion link was copied successfully.",
        tgcopytext: "The official customer telegram link has been copied successfully. Please search on the telegram APP and contact customer service.",
    },
    msg: {
        qingqiunoguanbi: "The request has not been completed. Cannot close the page!",
        wangluoyouwu: "Network error, please contact online customer service!",
        tibiyongwan: "The number of coin withdrawals today has been used up.",
        zuiditibi: "Minimum withdrawal is 5USDT!",
        meiyouusdt: "There is no USDT to withdraw from the current account.",
        canyushibai:"Participation failed, please contact customer service!",
        jiesuo:"Please unlock the wave field wallet first.",
        jujuelianjie:"You refused to connect the wallet."
    },
    text: {
        home: {
            jianjie:"Introducing Our Latest Bonus Event: Up to 15,000 USDT Bonus to be grab on our new launched Staking Increase & Recruitment Bonus programs.",
            lianjieqianbao: "Connect your wallet",
            jiedianxuanze: "Select the network node to connect to the wallet",
            jiedianxuanzequxiao: "Cancel",
            tuichumsg: "You have linked your wallet, are you sure you want to disconnect?",
            queding: "Confirm",
            mashangjiaru: "Join now",
            lingqu: "Apply",
            zhiyamoshi: "Staking Profit model",
            genduo: "More",
            zhiyamoshimiaoshu: `1：Connect your wallet to Liquid Stake DApp
        <br>2：Activate and approve the staking contract。
        <br>3：Receive pledged derivatives
        <br>4：Your tokens are stored in validators according to the protocol’s built-in mechanisms
        <br>5：Your staking derivatives start accruing rewards
        <br>6：You can use this Stake derivative in DeFi applications`,
            zongchanliang: 'Total output',
            canyuzhe: 'Participant',
            dangqiansuanli: 'Current computing power',
            yonghushouyi: 'User income',
            diyibu: {
                title: "Step 1: Connect Wallet",
                text: "Earn the first step of earning based on your USDT balance"
            },
            dierbu: {
                title: "Step 2: Join the pledge",
                text: "Receive pledged derivatives"
            },
            disanbu: {
                title: "Step 3: Receive rewards",
                text: "Your staking derivatives start accruing rewards"
            },
            disinbu: {
                title: "Step 4: Share your unique link",
                text: "Your subordinates will continue to earn more USDT for you"
            },
            shouyilv: {
                title: 'Yield Rate',
                dengji: 'Level',
                qujian: 'Interval',
                fanli: 'Rebate'
            },
            yinglimoshibili: {
                title: 'Profit model ratio',
                text: 'Cake Defi have diversified profit models, and different models have different profit ratios. Through this, you can clearly know how much the model accounts for.',
                list: {
                    fencheng: "Platform share",
                    zuchanzulin: "Asset leasing",
                    daibishouyi: "Token Earnings",
                    zhekou: "NFT discount income",
                    qita: "Other"
                }
            },
            yaoqing: {
                title: "Invite commission",
                text: "The platform has an invitation system to earn more USDT, and as a referrer, you will receive a considerable commission.",
                button: "Get 20USDT now"
            },
            userchanliang: "User income",
            help: {
                title: "Help",
                list: [
                    {
                        title: "How does Pancakeswap Cake DeFi make money?",
                        text: "In a PoS blockchain network, validators are selected to create new blocks and validate transactions based on the amount of cryptocurrency they hold and “stake” as collateral. The more cryptocurrency a validator stakes, the higher their chances of being selected to validate transactions and receive rewards."
                    },
                    {
                        title: "What are the conditions for participation?",
                        text: "Each participant must hold an agreement cooperation wallet: safepal, Trust, Tokenpocket, bitkeep, etc. The Participant's wallet needs to have BSC (BNB) , ERC (ETH) , TRC (TRX) & USDT ( BSC , ERC & TRC ) to participate."
                    },
                    {
                        title: "How do I participate?",
                        text: "Joining to obtain participation qualifications needs to be completed after paying a very small activation gas. Cake DeFi will distribute the primary income according to the USDT held by the participants. The more USDT, the higher the proportion of income will be obtained."
                    },
                    {
                        title: "What’s the difference between “Flexible” and “Fixed-Term” staking?",
                        text: "With both, you can simply stake your USDT tokens to earn more USDT tokens with no deposit fees. Flexible staking allows users to stake USDT and earn rewards with the ability to unstake whenever they please. Fixed-term staking allows users to maximise their yield and earn even more USDT by locking their staked USDT for a period of time they choose, earning a linearly boosted yield compared to flexible staking. Flexible staking and fixed-term staking are both part of the same pool to allow users easy migration between the two staking options."
                    },
                    {
                        title: "How do I invite friends?",
                        text: "Send your personal Referral link to your friends. You can pressing the Invite button in the upper left corner of the website."
                    },
                    {
                        title: "PoS Terms and Conditions:",
                        text: `By using our platform, you agree to the terms and conditions stated herein.<br><br>1. PoS Vol is a staking pool service that offers users the opportunity to earn interest on their cryptocurrency investments. To ensure the security and integrity of our platform, an audit fee is charged to users who have earned a certain amount of interest.<br><br>2. The audit fee is calculated based on the total amount invested including interest earned by a user and their downline. The fee is necessary to cover the cost of conducting regular audits and ensuring the safety and stability of our platform.<br><br>3. Once a user has earned a certain amount of interest and has withdrawn it from their PoS Vol account, the amount withdrawn will be subject to the audit fee. This fee cannot be waived or refunded.<br><br>4. Users are advised to keep track of their earnings and the audit fee associated with their account. Failure to pay the audit fee may result in account suspension or termination.<br><br>5. We reserve the right to modify or update these terms and conditions at any time without prior notice. It is the responsibility of the user to regularly review these terms and conditions to ensure compliance.<br><br>6.By using our platform, you acknowledge and agree to these terms and conditions, as well as our Privacy Policy and any other policies or guidelines posted on our website.`
                    }
                ]
            },
            shengjibaodao: {
                title: "Audit report",
                text: "We have secure audit reports"
            },

            banquan: "© Pancakeswap & Cake DeFi 2023 All rights reserved",
            
            award_forme:{
                title:"Share and get USDT rewards instantly",
                leiji:"cumulative",
                huode:"get",
                msg:"Invite friends to join. If your friends meet the following conditions, you will receive equivalent USDT rewards.",
            }
        },

        team: {
            team_title: "Choose a guild",
            tian: "day",
            shouyilv: "Rate of return",
            canyujinge: "Participation amount",
            button: "join",
            info: "Guild Details",
            qianbao: "Wallet account balance",
            zhouqi: "Cycle",
            meiri: "Daily rate",
            shoyi: "Real-time earnings",
            canyu: "Participate in investment",
            guizhe: "Guild rules",
            fangshi: "Settlement method",
            fangshi_text: "Dividends are paid daily and the principal is returned upon maturity",
            meirihuibao: "Daily rate of return",
            touzizhouqi: "Investment cycle",
            yujishouyi: "Estimated income",
            zongshouru: "Total revenue",
            teamjieshou: "Guild introduction",
            yicanyu: "Already participated"
        },
        fenxiang: {
            title: "Share with friends",
            text: "Send your invitation link, and if your friends join the node through your link, you can get 20 USDT, up to 1500 USDT and commission rewards, or if your friends invite their friends to join the node, you will get generous commission rewards!",
            fuzhi: "Copy",
            tuandui: {
                title: "Team information",
                shuliang: "Number of teams",
                yongjing: "Team commission",
                zuoriyongjing: "Yesterday's commission",
                xiayiji: "level 1",
                xiaerji: "level 2",
                xiangqing: "Team details",
            },
            biaoge: {
                title: "Share rewards",
                dengji: 'Level',
                qujian: 'Interval',
                fanli: 'Rebate'
            }
        },
        user: {
            qianbao: "Wallet balance",
            pos: "PoS vol",
            canyu: "Total participation",
            zongchanliang: "Total output",
            zuorishouyi: "Yesterday output",
            ketixian: "Withdrawable",
            tixian: "Withdraw",
            zongyue: "Total balance",
            tixiancishu: "Daily withdrawals",
            queren: "Confirm",
            chanliang: "Yield",
            jiangli: "Reward",
            zhiya: "Mortgage",
            shijian: "Time",
            shuliang: "Quantity",
            zhuangtai: "condition",
            nodata: "No data",
            zhuangtai_yes: "yes",
            zhuangtai_no: "no",
            zhuangtai_load: "Application in",
            shouxufei:"The withdrawal fee is 1.5 USDT"
        },
        teampage: {
            title: "Team details",
            xiayiji: "level 1",
            xiaerji: "level 2",
            dizhi: "Address",
            yongjing: "Commission",
            riqi:"Select date",
            jr: "Today",
            zr: "Yesterday",
            sy: "Last month",
            by: "This month"
        },
        kefu:{
            title:"online service",
            hello:"Hello!",
            text:"Since online customer service is currently busy, please contact our Telegram customer service for consultation!",
            tghao:"@pandefi"
        }
    },
}