<style scoped>
</style>
<template>
  <div class="layout-footer">
    <div
      class="item"
      @click="openurl('home')"
      :class="route.name == 'home' ? 'active' : ''"
    >
      <i class="icon icon0"></i>
      <p>{{ $t("tabbar.home") }}</p>
    </div>
    <div
      class="item"
      @click="openurl('union')"
      :class="route.name == 'union' ? 'active' : ''"
    >
      <i class="icon icon1"></i>
      <p>{{ $t("tabbar.union") }}</p>
    </div>
    <div
      class="item"
      @click="openurl('team')"
      :class="route.name == 'team' ? 'active' : ''"
    >
      <i class="icon icon2"></i>
      <p>{{ $t("tabbar.team") }}</p>
    </div>
    <div
      class="item"
      @click="openurl('user')"
      :class="route.name == 'user' ? 'active' : ''"
    >
      <i class="icon icon3"></i>
      <p>{{ $t("tabbar.user") }}</p>
    </div>
  </div>
</template>
<script setup>
import { useRouter, useRoute } from "vue-router";
import { useUserStore } from "@/pinia/User";
import {  showDialog } from 'vant';
import i18n from '@/i18n';


const router = useRouter();
const route = useRoute();
const userStore = useUserStore();

function openurl(name) {
  if (name != 'home' && !userStore.address) {
    showDialog({
        message: i18n.global.t('text.home.lianjieqianbao'),
        'confirm-button-text':i18n.global.t('conn.queding')
    })
    return;
  }

  router.push({
    name: name,
  });
}
</script>