/*
    APP首页数据模型
*/
var model = {
    homeData: {
        NetworkArr: [],
        tg_link: "",
        anget_promotion: null,
        total_output: null,
        total_participants: null,
        computing: null,
        user_benefits: null,
        poster: "",
        notice_bar: {
            type: "bar",
            list: []
        },
        picturepie: {
            fencheng: null,
            zulin: null,
            shouyi: null,
            zhekou: null,
            qita: null
        },
        tokenInfo: {
            name: "",
            decimals: null,
            symbol: "",
            chainid: "",
            icon: "",
            usdt: null,
            address: ""
        }
    }
}

function formToJSON(json) {
    if (!json) {
        return model.homeData;
    }
    var backobj = {};
    if (typeof json == 'string') {
        json = JSON.parse(json);
    }
    for (const key in json) {
        for (const key2 in model.homeData) {
            if (key == key2) {
                if (key == 'notice_bar' && json[key]['type'] == 'bar') {
                    var itemListStr = "";
                    for (let index = 0; index < json[key]['list'].length; index++) {
                        itemListStr += itemListStr ? ';' + json[key]['list'][index] : json[key]['list'][index];
                    }
                    json[key]['list'] = itemListStr;
                }
                backobj[key] = json[key];
            }
        }
    }
    return backobj;
}


export {
    formToJSON,
    model
}