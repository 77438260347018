export default {
    tabbar: {
        home: "Heim",
        union: "Gilde",
        team: "Team",
        user: "Mein"
    },
    conn: {
        queding: "Bestätigen",
        quxiao: "Stornieren",
        yicanyu: "Habe bereits teilgenommen",
        copytext: "Ihr Werbelink wurde erfolgreich kopiert.",
        tgcopytext: "Das offizielle Kundendienstkonto von Telegram wurde erfolgreich kopiert. Bitte kontaktieren Sie den Kundendienst über die Telegram-APP",
    },
    msg: {
        qingqiunoguanbi: "Die Anfrage wurde nicht abgeschlossen. Die Seite kann nicht geschlossen werden!",
        wangluoyouwu: "Netzwerkfehler, bitte wenden Sie sich an den Online-Kundendienst!",
        tibiyongwan: "Die Anzahl der heutigen Münzabhebungen ist aufgebraucht.",
        zuiditibi: "Die Mindestauszahlung beträgt 5USDT!",
        meiyouusdt: "Es gibt kein USDT zum Abheben vom Girokonto.",
        canyushibai: "Die Teilnahme ist fehlgeschlagen, bitte wenden Sie sich an den Kundenservice!",
        jiesuo: "Bitte entsperren Sie zuerst das Wave Field Wallet.",
        jujuelianjie: "Sie haben sich geweigert, das Wallet zu verbinden."
    },
    text: {
        home: {
            jianjie: "Wir stellen Ihnen unser neuestes Bonus-Event vor: Bis zu 15.000 USDT-Bonus können Sie sich bei unseren neu eingeführten Einsatzerhöhungs- und Rekrutierungsbonusprogrammen sichern.",
            lianjieqianbao: "Verbinden Sie Ihr Portemonnaie",
            jiedianxuanze: "Wählen Sie den Netzwerkknoten aus, um eine Verbindung zum Wallet herzustellen",
            jiedianxuanzequxiao: "Stornieren",
            tuichumsg: "Sie haben Ihr Wallet verknüpft. Möchten Sie die Verbindung wirklich trennen?",
            queding: "Bestätigen",
            mashangjiaru: "Jetzt beitreten",
            lingqu: "Anwenden",
            zhiyamoshi: "Staking-Profit-Modell",
            genduo: "Mehr",
            zhiyamoshimiaoshu: `1: Verbinden Sie Ihr Wallet mit Liquid Stake DApp
            <br>2:Aktivieren und genehmigen Sie den Einsatzvertrag.
            <br>3:Erhalten Sie verpfändete Derivate
            <br>4:Ihre Token werden gemäß den integrierten Mechanismen des Protokolls in Validatoren gespeichert
            <br>5:Ihre Einsatzderivate beginnen, Belohnungen zu sammeln
            <br>6:Sie können dieses Stake-Derivat in DeFi-Anwendungen verwenden`,
            zongchanliang: 'Gesamtleistung',
            canyuzhe: 'Teilnehmer',
            dangqiansuanli: 'Aktuelle Rechenleistung',
            yonghushouyi: 'Benutzereinkommen',
            diyibu: {
                title: "Schritt 1: Wallet verbinden",
                text: "Verdienen Sie den ersten Verdienstschritt basierend auf Ihrem USDT-Guthaben"
            },
            dierbu: {
                title: "Schritt 2: Treten Sie dem Versprechen bei",
                text: "Erhalten Sie verpfändete Derivate"
            },
            disanbu: {
                title: "Schritt 3: Belohnungen erhalten",
                text: "Ihre Einsatzderivate beginnen, Belohnungen zu sammeln"
            },
            disinbu: {
                title: "Schritt 4: Teilen Sie Ihren einzigartigen Link",
                text: "Ihre Untergebenen werden weiterhin mehr USDT für Sie verdienen"
            },
            shouyilv: {
                title: 'Ertragsrate',
                dengji: 'Ebene',
                qujian: 'Intervall',
                fanli: 'Abzug'
            },
            yinglimoshibili: {
                title: 'Gewinnmodellquote',
                text: 'Cake Defi verfügt über diversifizierte Gewinnmodelle und verschiedene Modelle haben unterschiedliche Gewinnquoten. Dadurch können Sie klar erkennen, wie viel das Modell ausmacht.',
                list: {
                    fencheng: "Plattformfreigabe",
                    zuchanzulin: "Vermögensleasing",
                    daibishouyi: "Token-Einnahmen",
                    zhekou: "NFT-Rabatteinkommen",
                    qita: "Andere"
                }
            },
            yaoqing: {
                title: "Kommission einladen",
                text: "Die Plattform verfügt über ein Einladungssystem, um mehr USDT zu verdienen, und als Empfehlungsgeber erhalten Sie eine beträchtliche Provision.",
                button: "Holen Sie sich jetzt 20USDT"
            },
            userchanliang: "Benutzereinkommen",
            help: {
                title: "Helfen",
                list: [
                    {
                        title: "Wie verdient Pancakeswap Cake DeFi Geld?",
                        text: "In einem PoS-Blockchain-Netzwerk werden Validatoren ausgewählt, um neue Blöcke zu erstellen und Transaktionen basierend auf der Menge an Kryptowährung zu validieren, die sie halten und als Sicherheit „einsetzen“. Je mehr Kryptowährung ein Validator einsetzt, desto höher sind seine Chancen, für die Validierung von Transaktionen und den Erhalt von Belohnungen ausgewählt zu werden."
                    },
                    {
                        title: "Was sind die Teilnahmebedingungen?",
                        text: "Jeder Teilnehmer muss über eine vertragliche Kooperations-Wallet verfügen: Safepal, Trust, Tokenpocket, Bitkeep usw. Die Wallet des Teilnehmers muss über BSC (BNB), ERC (ETH), TRC (TRX) und USDT (BSC, ERC und TRC) verfügen, um teilnehmen zu können ."
                    },
                    {
                        title: "Wie kann ich teilnehmen?",
                        text: "Der Beitritt zur Erlangung der Teilnahmeberechtigung muss nach Zahlung einer sehr geringen Aktivierungsgebühr abgeschlossen werden. Cake DeFi verteilt das Primäreinkommen entsprechend dem von den Teilnehmern gehaltenen USDT. Je mehr USDT, desto höher ist der Anteil des Einkommens."
                    },
                    {
                        title: "Was ist der Unterschied zwischen „flexiblem“ und „befristetem“ Einsatz?",
                        text: "Bei beiden können Sie einfach Ihre USDT-Tokens einsetzen, um ohne Einzahlungsgebühren mehr USDT-Tokens zu verdienen. Dank flexiblem Einsatz können Benutzer USDT einsetzen und Belohnungen verdienen, indem sie den Einsatz jederzeit wieder aufheben können. Mit dem Einsatz mit fester Laufzeit können Benutzer ihren Ertrag maximieren und noch mehr USDT verdienen, indem sie ihre eingesetzten USDT für einen von ihnen gewählten Zeitraum sperren und so im Vergleich zum flexiblen Einsatz einen linear höheren Ertrag erzielen. Flexibles Abstecken und zeitlich begrenztes Abstecken sind beide Teil desselben Pools, um Benutzern einen einfachen Wechsel zwischen den beiden Absteckoptionen zu ermöglichen."
                    },
                    {
                        title: "Wie lade ich Freunde ein?",
                        text: "Senden Sie Ihren persönlichen Empfehlungslink an Ihre Freunde. Sie können auf die Schaltfläche „Einladen“ in der oberen linken Ecke der Website klicken."
                    },
                    {
                        title: "PoS-Geschäftsbedingungen:",
                        text: `Durch die Nutzung unserer Plattform stimmen Sie den hierin aufgeführten Geschäftsbedingungen zu.<br><br>1. PoS Vol ist ein Staking-Pool-Dienst, der Benutzern die Möglichkeit bietet, Zinsen auf ihre Kryptowährungsinvestitionen zu verdienen. Um die Sicherheit und Integrität unserer Plattform zu gewährleisten, wird von Benutzern, die einen bestimmten Zinsbetrag verdient haben, eine Prüfgebühr erhoben.<br><br>2. Die Prüfungsgebühr wird auf der Grundlage des Gesamtinvestitionsbetrags einschließlich der von einem Benutzer und seiner Downline verdienten Zinsen berechnet. Die Gebühr ist notwendig, um die Kosten für die Durchführung regelmäßiger Audits und die Gewährleistung der Sicherheit und Stabilität unserer Plattform zu decken.<br><br>3. Sobald ein Benutzer einen bestimmten Zinsbetrag verdient und diesen von seinem PoS Vol-Konto abgehoben hat, unterliegt der abgehobene Betrag der Prüfungsgebühr. Auf diese Gebühr kann nicht verzichtet oder sie erstattet werden.<br><br>4. Benutzern wird empfohlen, ihre Einnahmen und die mit ihrem Konto verbundene Prüfungsgebühr im Auge zu behalten. Die Nichtzahlung der Prüfungsgebühr kann zur Sperrung oder Kündigung des Kontos führen.<br><br>5. Wir behalten uns das Recht vor, diese Allgemeinen Geschäftsbedingungen jederzeit und ohne vorherige Ankündigung zu ändern oder zu aktualisieren. Es liegt in der Verantwortung des Benutzers, diese Allgemeinen Geschäftsbedingungen regelmäßig zu überprüfen, um die Einhaltung sicherzustellen.<br><br>6. Durch die Nutzung unserer Plattform erkennen Sie diese Allgemeinen Geschäftsbedingungen sowie unsere Datenschutzrichtlinie und alle anderen an und stimmen ihnen zu Richtlinien oder Leitlinien, die auf unserer Website veröffentlicht sind.`
                    }
                ]
            },
            shengjibaodao: {
                title: "Prüfbericht",
                text: "Wir verfügen über sichere Prüfberichte"
            },

            banquan: "© Pancakeswap & Cake DeFi 2023 Alle Rechte vorbehalten",

            award_forme: {
                title: "Teilen Sie und erhalten Sie sofort USDT-Prämien",
                leiji: "kumulativ",
                huode: "erhalten",
                msg: "Laden Sie Freunde ein, mitzumachen. Wenn Ihre Freunde die folgenden Bedingungen erfüllen, erhalten Sie entsprechende USDT-Prämien.",
            }
        },

        team: {
            team_title: "Wähle eine Gilde",
            tian: "Tag",
            shouyilv: "Rendite",
            canyujinge: "Beteiligungsbetrag",
            button: "verbinden",
            info: "Gildendetails",
            qianbao: "Guthaben auf dem Wallet-Konto",
            zhouqi: "Zyklus",
            meiri: "Tagesrate",
            shoyi: "Einnahmen in Echtzeit",
            canyu: "Beteiligen Sie sich an Investitionen",
            guizhe: "Beteiligen Sie sich an Investitionen",
            fangshi: "Abrechnungsmethode",
            fangshi_text: "Dividenden werden täglich ausgezahlt und der Kapitalbetrag wird bei Fälligkeit zurückgezahlt",
            meirihuibao: "Tägliche Rendite",
            touzizhouqi: "Investitionszyklus",
            yujishouyi: "Geschätztes Einkommen",
            zongshouru: "Gesamtumsatz",
            teamjieshou: "Einführung in die Gilde",
            yicanyu: "Habe bereits teilgenommen"
        },
        fenxiang: {
            title: "Mit Freunden teilen",
            text: "Senden Sie Ihren Einladungslink, und wenn Ihre Freunde über Ihren Link dem Knoten beitreten, können Sie 20 USDT, bis zu 1500 USDT und Provisionsprämien erhalten, oder wenn Ihre Freunde ihre Freunde einladen, dem Knoten beizutreten, erhalten Sie großzügige Provisionsprämien!",
            fuzhi: "Kopieren",
            tuandui: {
                title: "Teaminformationen",
                shuliang: "Anzahl der Teams",
                yongjing: "Teamprovision",
                zuoriyongjing: "Die Kommission von gestern",
                xiayiji: "Ebene 1",
                xiaerji: "Ebene 2",
                xiangqing: "Teamdetails",
            },
            biaoge: {
                title: "Belohnung teilen",
                dengji: 'Ebene',
                qujian: 'Intervall',
                fanli: 'Abzug'
            }
        },
        user: {
            qianbao: "Wallet-Guthaben",
            pos: "PoS-Vol",
            canyu: "Gesamtbeteiligung",
            zongchanliang: "Gesamtleistung",
            zuorishouyi: "Ausgabe von gestern",
            ketixian: "Ausziehbar",
            tixian: "Zurückziehen",
            zongyue: "Gesamtsaldo",
            tixiancishu: "Tägliche Abhebungen",
            queren: "Bestätigen",
            chanliang: "Ertrag",
            jiangli: "Belohnen",
            zhiya: "Hypothek",
            shijian: "Zeit",
            shuliang: "Menge",
            zhuangtai: "Zustand",
            nodata: "Keine Daten",
            zhuangtai_yes: "Ja",
            zhuangtai_no: "NEIN",
            zhuangtai_load: "Bewerbung im",
            shouxufei: "Die Auszahlungsgebühr beträgt 1,5 USDT"
        },
        teampage: {
            title: "Teamdetails",
            xiayiji: "Ebene 1",
            xiaerji: "Ebene 2",
            dizhi: "Adresse",
            yongjing: "Kommission",
            riqi: "Datum auswählen",
            jr: "Heute",
            zr: "Gestern",
            sy: "Zuletzt Monat",
            by: "Das Monat"
        },
        kefu:{
            title:"Onlineservice",
            hello:"Hallo!",
            text:"Da der Online-Kundendienst derzeit ausgelastet ist, wenden Sie sich bitte für eine Beratung an unseren Telegram-Kundendienst!",
            tghao:"@pandefi"
        }
    },
}