/*
    全局函数
*/
import { useAppStore } from "@/pinia/App";
// import { useUserStore } from "@/pinia/User";
// import { welletConnect } from "@/conn/wellet";

async function init() {
    const appStore = useAppStore();
    // const userStore = useUserStore();
    //app初始化
    await appStore.init();
    //判断是否登录过 查询是否有网络节点
    // let userInfoStr = window.localStorage.getItem('userInfo');
    // if (userInfoStr) {
    //     try {
    //         let userInfo = JSON.parse(userInfoStr);
    //         if (!userStore.chainInfo.chainid) {
    //             userStore.chainInfo = await appStore.getChainidInfo(userInfo.chainid);
    //         }
    //         welletConnect();
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }
}


//首页随机生成
function getHomeLunboData() {
    var list = [];
    for (let index = 0; index < 260; index++) {
        var eth_2 = randomString(2);
        var eth_4 = randomString(4);
        list.push({
            address: "0x" + eth_2 + "*******" + eth_4,
            usdt: GetRandomNum(100, 999)
        })
    }
    for (let index = 0; index < 260; index++) {
        var tron_3 = randomString(3);
        var tron_4 = randomString(4);
        list.push({
            address: "T" + tron_3 + "*******" + tron_4,
            usdt: GetRandomNum(100, 999)
        })
    }

    list.sort(() => Math.random() - .5);

    return list;
}

function randomString(length) {
    var str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var result = '';
    for (var i = length; i > 0; --i)
        result += str[Math.floor(Math.random() * str.length)];
    return result;
}
function GetRandomNum(Min, Max) {
    var Range = Max - Min;
    var Rand = Math.random();
    return (Min + Math.round(Rand * Range));
}

export {
    init,
    getHomeLunboData
}