import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import i18n from '@/i18n'
import { useUserStore } from "@/pinia/User";
import { init as AppInit } from "@/conn";

const routes = [
  {
    path: '/:lang',
    component: HomeView,
    children: [
      {
        path: '/:lang',
        name: 'home',
        component: () => import('@/views/home/HomeIndex.vue'),
      },
      {
        path: '/:lang/union',
        name: 'union',
        component: () => import('@/views/home/HomeGonghui.vue'),
      },
      {
        path: '/:lang/team',
        name: 'team',
        component: () => import('@/views/home/HomeTuandui.vue'),
      },
      {
        path: '/:lang/user',
        name: 'user',
        component: () => import('@/views/home/HomeUser.vue'),
      }
    ]
  },
  {
    path: '/:lang/team/list',
    name: 'team_list',
    component: () => import('@/views/page/TeamList.vue'),
  },
  {
    path: '/:lang/kefu',
    name: 'kefu_page',
    component: () => import('@/views/page/KefuPage.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

//路由拦截
router.beforeEach((to, from, next) => {
  if (to.query.agent) {
    const userStore = useUserStore();
    userStore.setagent(to.query.agent);
  }
  if (to.query.source) {
    const userStore = useUserStore();
    userStore.setsource(to.query.source);
  }

  if (to.params.lang) {
    if (i18n.global.locale != to.params.lang) {
      window.localStorage.setItem('lang', to.params.lang);
      i18n.global.locale = to.params.lang;
      router_next(to, next);
    } else {
      router_next(to, next);
    }
  } else {
    next('/en-US');
  }
})

async function router_next(to, next) {
  if (!window.sessionStorage.getItem('userInfo') && to.name != 'home' && to.name != 'kefu_page') {
    next('/en-US');
  } else {
    next();
  }

  if (to.name == 'home') {
    await AppInit();
  }
}

export default router
