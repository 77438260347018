
<template>
  <div class="home">
    <router-view></router-view>
    <app-tabbar></app-tabbar>
    <van-floating-bubble
      v-model:offset="offset"
      :style="{
        backgroundImage: 'url(' + Telegram_logo + ')',
        backgroundSize: '100% 100%',
      }"
      @click="onClick"
    />
  </div>
</template>
<script setup>
import AppTabbar from "@/components/AppTabbar.vue";
import { useWindowSize } from "@vant/use";
import { ref } from "vue";
import Telegram_logo from "@/assets/Telegram_logo.svg.webp";

import { useRouter } from "vue-router";
const router = useRouter();

const { height } = useWindowSize();
const offset = ref({ y: height.value - 190 });


function onClick() {
  router.push({
    name:"kefu_page"
  })
}

</script>
<style >
.van-popup--bottom.van-popup--round {
  background-color: #fff;
}
</style>