export default {
    tabbar: {
        home: "Hogar",
        union: "Gremio",
        team: "Equipo",
        user: "Mi"
    },
    conn: {
        queding: "Confirmar",
        quxiao: "Cancelar",
        yicanyu: "Ya participé",
        copytext: "Su enlace de promoción se copió correctamente.",
        tgcopytext: "La cuenta oficial de servicio al cliente de Telegram se ha copiado correctamente. Comuníquese con el servicio de atención al cliente en la aplicación Telegram.",
    },
    msg: {
        qingqiunoguanbi: "La solicitud no se ha completado. ¡No se puede cerrar la página!",
        wangluoyouwu: "Error de red, póngase en contacto con el servicio de atención al cliente en línea.",
        tibiyongwan: "El número de retiros de monedas de hoy se ha agotado.",
        zuiditibi: "¡El retiro mínimo es 5USDT!",
        meiyouusdt: "No hay USDT para retirar de la cuenta corriente.",
        canyushibai:"La participación falló, ¡póngase en contacto con el servicio de atención al cliente!",
        jiesuo:"Primero desbloquee la billetera Wave Field.",
        jujuelianjie:"Te negaste a conectar la billetera."
    },
    text: {
        home: {
            jianjie:"Presentamos nuestro último evento de bonificación: Bonificación de hasta 15 000 USDT para aprovechar en nuestros nuevos programas de bonificación de reclutamiento y aumento de apuestas lanzados.",
            lianjieqianbao: "Conecta tu billetera",
            jiedianxuanze: "Seleccione el nodo de red para conectarse a la billetera",
            jiedianxuanzequxiao: "Cancelar",
            tuichumsg: "Has vinculado tu billetera, ¿estás seguro de que deseas desconectarte?",
            queding: "Confirmar",
            mashangjiaru: "Únete ahora",
            lingqu: "Aplicar",
            zhiyamoshi: "Modelo de beneficio de apuesta",
            genduo: "Más",
            zhiyamoshimiaoshu: `1: Conecte su billetera a la DApp Liquid Stake
            <br>2: Activar y aprobar el contrato de participación.
            <br>3: Recibir derivados pignorados
            <br>4: Sus tokens se almacenan en validadores de acuerdo con los mecanismos integrados del protocolo.
            <br>5: Sus derivados de apuestas comienzan a acumular recompensas
            <br>6: Puede utilizar este derivado de participación en aplicaciones DeFi`,
            zongchanliang: 'Producción total',
            canyuzhe: 'Partícipe',
            dangqiansuanli: 'Potencia informática actual',
            yonghushouyi: 'Ingresos del usuario',
            diyibu: {
                title: "Paso 1: conectar la billetera",
                text: "Obtenga el primer paso para ganar según su saldo de USDT"
            },
            dierbu: {
                title: "Paso 2: únete al compromiso",
                text: "Recibir derivados pignorados"
            },
            disanbu: {
                title: "Paso 3: recibe recompensas",
                text: "Tus derivados en juego comienzan a acumular recompensas"
            },
            disinbu: {
                title: "Paso 4: comparte tu enlace único",
                text: "Tus subordinados seguirán ganando más USDT para ti"
            },
            shouyilv: {
                title: 'Tasa de rendimiento',
                dengji: 'Nivel',
                qujian: 'Intervalo',
                fanli: 'Reembolso'
            },
            yinglimoshibili: {
                title: 'Relación del modelo de beneficios',
                text: 'Cake Defi tiene modelos de ganancias diversificados y diferentes modelos tienen diferentes proporciones de ganancias. A través de esto, puedes saber claramente cuánto representa el modelo.',
                list: {
                    fencheng: "Compartir plataforma",
                    zuchanzulin: "Arrendamiento de activos",
                    daibishouyi: "Ganancias simbólicas",
                    zhekou: "Ingresos con descuento NFT",
                    qita: "Otro"
                }
            },
            yaoqing: {
                title: "comisión de invitación",
                text: "La plataforma cuenta con un sistema de invitaciones para ganar más USDT y, como referente, recibirás una comisión considerable.",
                button: "Obtenga 20 USDT ahora"
            },
            userchanliang: "Ingresos del usuario",
            help: {
                title: "Ayuda",
                list: [
                    {
                        title: "¿Cómo gana dinero Pancakeswap Cake DeFi?",
                        text: `En una red blockchain de PoS, los validadores se seleccionan para crear nuevos bloques y validar transacciones en función de la cantidad de criptomonedas que poseen y "apuestan" como garantía. Cuantas más criptomonedas apueste un validador, mayores serán sus posibilidades de ser seleccionado para validar transacciones y recibir recompensas.`
                    },
                    {
                        title: "¿Cuáles son las condiciones para participar?",
                        text: "Cada participante debe tener una billetera de cooperación del acuerdo: safepal, Trust, Tokenpocket, bitkeep, etc. La billetera del participante debe tener BSC (BNB), ERC (ETH), TRC (TRX) y USDT (BSC, ERC y TRC) para participar. ."
                    },
                    {
                        title: "¿Cómo participo?",
                        text: "Unirse para obtener las calificaciones de participación debe completarse después de pagar un gas de activación muy pequeño. Cake DeFi distribuirá los ingresos primarios de acuerdo con el USDT que tengan los participantes. Cuanto más USDT, mayor será la proporción de ingresos que se obtendrá."
                    },
                    {
                        title: `¿Cuál es la diferencia entre apuestas “flexibles” y de “plazo fijo”?`,
                        text: "Con ambos, puedes simplemente apostar tus tokens USDT para ganar más tokens USDT sin tarifas de depósito. La apuesta flexible permite a los usuarios apostar USDT y ganar recompensas con la posibilidad de retirar la apuesta cuando lo deseen. Las apuestas a plazo fijo permiten a los usuarios maximizar su rendimiento y ganar aún más USDT al bloquear su USDT apostado durante un período de tiempo que elijan, obteniendo un rendimiento aumentado linealmente en comparación con las apuestas flexibles. Las apuestas flexibles y las apuestas a plazo fijo son parte del mismo grupo para permitir a los usuarios una fácil migración entre las dos opciones de apuesta."
                    },
                    {
                        title: "¿Cómo invito a amigos?",
                        text: "Envía tu enlace de referencia personal a tus amigos. Puede presionar el botón Invitar en la esquina superior izquierda del sitio web."
                    },
                    {
                        title: "Términos y condiciones de punto de venta:",
                        text: `Al utilizar nuestra plataforma, usted acepta los términos y condiciones establecidos en este documento.<br><br>1. PoS Vol es un servicio de grupo de apuestas que ofrece a los usuarios la oportunidad de ganar intereses sobre sus inversiones en criptomonedas. Para garantizar la seguridad e integridad de nuestra plataforma, se cobra una tarifa de auditoría a los usuarios que han obtenido una cierta cantidad de interés.<br><br>2. La tarifa de auditoría se calcula en función del monto total invertido, incluidos los intereses ganados por un usuario y su línea descendente. La tarifa es necesaria para cubrir el costo de realizar auditorías periódicas y garantizar la seguridad y estabilidad de nuestra plataforma.<br><br>3. Una vez que un usuario haya ganado una cierta cantidad de intereses y los haya retirado de su cuenta PoS Vol, el monto retirado estará sujeto a la tarifa de auditoría. Esta tarifa no se puede renunciar ni reembolsar.<br><br>4. Se recomienda a los usuarios que realicen un seguimiento de sus ganancias y de la tarifa de auditoría asociada a su cuenta. La falta de pago de la tarifa de auditoría puede resultar en la suspensión o cancelación de la cuenta.<br><br>5. Nos reservamos el derecho de modificar o actualizar estos términos y condiciones en cualquier momento sin previo aviso. Es responsabilidad del usuario revisar periódicamente estos términos y condiciones para garantizar su cumplimiento.<br><br>6. Al utilizar nuestra plataforma, usted reconoce y acepta estos términos y condiciones, así como nuestra Política de Privacidad y cualquier otra políticas o pautas publicadas en nuestro sitio web.`
                    }
                ]
            },
            shengjibaodao: {
                title: "Informe de auditoría",
                text: "Contamos con informes de auditoría seguros"
            },

            banquan: "© Pancakeswap & Cake DeFi 2023 Todos los derechos reservados",
            
            award_forme:{
                title:"Comparte y obtén recompensas en USDT al instante",
                leiji:"acumulativo",
                huode:"llegar",
                msg:"Invita a amigos a unirse. Si tus amigos cumplen con las siguientes condiciones, recibirás recompensas en USDT equivalentes.",
            }
        },

        team: {
            team_title: "Elige un gremio",
            tian: "día",
            shouyilv: "Tasa de retorno",
            canyujinge: "Monto de participación",
            button: "unirse",
            info: "Detalles del gremio",
            qianbao: "Saldo de la cuenta de billetera",
            zhouqi: "Ciclo",
            meiri: "Tarifa diaria",
            shoyi: "Ganancias en tiempo real",
            canyu: "participar en la inversión",
            guizhe: "reglas del gremio",
            fangshi: "Método de liquidación",
            fangshi_text: "Los dividendos se pagan diariamente y el principal se devuelve al vencimiento.",
            meirihuibao: "Tasa de rendimiento diaria",
            touzizhouqi: "Ciclo de inversión",
            yujishouyi: "Ingresos estimados",
            zongshouru: "Los ingresos totales",
            teamjieshou: "Introducción del gremio",
            yicanyu: "Ya participé"
        },
        fenxiang: {
            title: "Compartir con amigos",
            text: "Envíe su enlace de invitación y, si sus amigos se unen al nodo a través de su enlace, podrá obtener 20 USDT, hasta 1500 USDT y recompensas de comisión, o si sus amigos invitan a sus amigos a unirse al nodo, ¡obtendrá generosas recompensas de comisión!",
            fuzhi: "Copiar",
            tuandui: {
                title: "Información del equipo",
                shuliang: "Número de equipos",
                yongjing: "Comisión de equipo",
                zuoriyongjing: "La comisión de ayer",
                xiayiji: "Nivel 1",
                xiaerji: "Nivel 2",
                xiangqing: "Detalles del equipo",
            },
            biaoge: {
                title: "Compartir las recompensas",
                dengji: 'Nivel',
                qujian: 'Intervalo',
                fanli: 'Reembolso'
            }
        },
        user: {
            qianbao: "Saldo de billetera",
            pos: "PoS vol",
            canyu: "Participación total",
            zongchanliang: "Producción total",
            zuorishouyi: "salida de ayer",
            ketixian: "Retirable",
            tixian: "Retirar",
            zongyue: "Balance total",
            tixiancishu: "Retiros diarios",
            queren: "Confirmar",
            chanliang: "Producir",
            jiangli: "Premio",
            zhiya: "Hipoteca",
            shijian: "Tiempo",
            shuliang: "Cantidad",
            zhuangtai: "condición",
            nodata: "Sin datos",
            zhuangtai_yes: "Sí",
            zhuangtai_no: "No",
            zhuangtai_load: "Aplicación en",
            shouxufei:"La tarifa de retiro es de 1,5 USDT"
        },
        teampage: {
            title: "Detalles del equipo",
            xiayiji: "Nivel 1",
            xiaerji: "Nivel 2",
            dizhi: "DIRECCIÓN",
            yongjing: "Comisión",
            riqi:"Seleccione fecha",
            jr: "Hoy",
            zr: "Ayer",
            sy: "El mes pasado",
            by: "Este mes"
        },
        kefu:{
            title:"Servicio en línea",
            hello:"¡Hola!",
            text:"Dado que el servicio de atención al cliente en línea está actualmente ocupado, comuníquese con nuestro servicio de atención al cliente de Telegram para realizar consultas.",
            tghao:"@pandefi"
        }
    },
}