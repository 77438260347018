import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { createPinia } from 'pinia'
const pinia = createPinia()

import Vant from 'vant'
import 'vant/lib/index.css'
import '@/assets/css/app.css'

import i18n from './i18n'

const app = createApp(App)

app.use(i18n)
app.use(Vant)
app.use(pinia)
app.use(router).mount('#app')