import { defineStore } from 'pinia'

import instance from "@/axios";
import { model, formToJSON } from "@/conn/model/appDataModel";

import i18n from "@/i18n";


export const useAppStore = defineStore('app', {
    state: () => ({
        homeData: model.homeData,
        gradeList: [],
        homeTubiao: {
            data: {
                labels: [
                    i18n.global.t('text.home.yinglimoshibili.list.fencheng'), 
                    i18n.global.t('text.home.yinglimoshibili.list.zuchanzulin'), 
                    i18n.global.t('text.home.yinglimoshibili.list.daibishouyi'), 
                    i18n.global.t('text.home.yinglimoshibili.list.zhekou'), 
                    i18n.global.t('text.home.yinglimoshibili.list.qita'), 
                ],
                datasets: [],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
        },
    }),
    actions: {
        /*
            获取当前APP配置
        */
        async init() {
            var res = await instance.post('/get_apinfo');
            for (let index = 0; index < res.data.NetworkArr.length; index++) {
                res.data.NetworkArr[index]['name'] = res.data.NetworkArr[index]['title'];
            }

            let TubiaoList = [];
            for (const key in res.data.picturepie) {
                TubiaoList.push(res.data.picturepie[key]);
            }

            var homeDataObj = formToJSON(res.data);
            this.homeData = homeDataObj;

            this.gradeList = res.gradeList;
            this.homeTubiao.data = {
                labels: [
                    i18n.global.t('text.home.yinglimoshibili.list.fencheng'), 
                    i18n.global.t('text.home.yinglimoshibili.list.zuchanzulin'), 
                    i18n.global.t('text.home.yinglimoshibili.list.daibishouyi'), 
                    i18n.global.t('text.home.yinglimoshibili.list.zhekou'), 
                    i18n.global.t('text.home.yinglimoshibili.list.qita'), 
                ],
                datasets: [
                    {
                        backgroundColor: [
                            "#41B883",
                            "#E46651",
                            "#00D8FF",
                            "#DD1B16",
                            "#DD1B16"
                        ],
                        data: TubiaoList,
                    },
                ],
            };
        },

        //获取当前网络节点信息
        getChainidInfo(chainid = 56) {
            for (let index = 0; index < this.homeData.NetworkArr.length; index++) {
                if (Number(this.homeData.NetworkArr[index]['chainid']) == Number(chainid)) {
                    return this.homeData.NetworkArr[index];
                }
            }
        }
    },
})