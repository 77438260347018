export default {
    tabbar: {
        home: "집",
        union: "동업 조합",
        team: "팀",
        user: "나의"
    },
    conn: {
        queding: "확인하다",
        quxiao: "취소",
        yicanyu: "이미 참여했습니다",
        copytext: "프로모션 링크가 성공적으로 복사되었습니다.",
        tgcopytext: "텔레그램 공식 고객 서비스 계정이 성공적으로 복사되었습니다. 텔레그램 앱에서 고객 서비스에 문의하세요.",
    },
    msg: {
        qingqiunoguanbi: "요청이 완료되지 않았습니다. 페이지를 닫을 수 없습니다!",
        wangluoyouwu: "네트워크 오류입니다. 온라인 고객센터에 문의하세요!",
        tibiyongwan: "금일 코인 출금 횟수가 모두 소진되었습니다.",
        zuiditibi: "최소 출금 금액은 5USDT입니다!",
        meiyouusdt: "현재 계좌에서 인출할 USDT가 없습니다.",
        canyushibai: "참여에 실패했습니다. 고객센터로 문의해주세요!",
        jiesuo: "먼저 웨이브 필드 지갑을 잠금해제해 주세요.",
        jujuelianjie: "지갑 연결을 거부하셨습니다."
    },
    text: {
        home: {
            jianjie: "최신 보너스 이벤트 소개: 새롭게 출시된 스테이킹 증가 및 모집 보너스 프로그램에서 최대 15,000 USDT 보너스를 받으세요.",
            lianjieqianbao: "지갑을 연결하세요",
            jiedianxuanze: "지갑에 연결할 네트워크 노드를 선택하세요.",
            jiedianxuanzequxiao: "취소",
            tuichumsg: "지갑을 연결했습니다. 연결을 해제하시겠습니까?",
            queding: "확인하다",
            mashangjiaru: "지금 가입하세요",
            lingqu: "적용하다",
            zhiyamoshi: "스테이킹 수익 모델",
            genduo: "더",
            zhiyamoshimiaoshu: `1: 지갑을 Liquid Stake DApp에 연결하세요
            <br>2：스테이킹 계약 활성화 및 승인。
            <br>3：담보파생상품 수령
            <br>4: 토큰은 프로토콜에 내장된 메커니즘에 따라 유효성 검사기에 저장됩니다.
            <br>5: 귀하의 스테이킹 파생상품이 보상을 받기 시작합니다.
            <br>6：이 스테이크 파생상품을 DeFi 애플리케이션에서 사용할 수 있습니다`,
            zongchanliang: '총 생산량',
            canyuzhe: '참가자',
            dangqiansuanli: '현재 컴퓨팅 성능',
            yonghushouyi: '사용자 소득',
            diyibu: {
                title: "1단계: 월렛 연결",
                text: "USDT 잔액을 기준으로 수익을 창출하는 첫 번째 단계를 받으세요"
            },
            dierbu: {
                title: "2단계: 서약에 참여하세요",
                text: "담보파생상품 수령"
            },
            disanbu: {
                title: "3단계: 보상 받기",
                text: "귀하의 스테이킹 파생상품이 보상을 받기 시작합니다."
            },
            disinbu: {
                title: "4단계: 고유 링크 공유",
                text: "귀하의 부하 직원은 귀하를 위해 계속해서 더 많은 USDT를 벌게 됩니다."
            },
            shouyilv: {
                title: '수율',
                dengji: '수준',
                qujian: '간격',
                fanli: '할인'
            },
            yinglimoshibili: {
                title: '수익모델 비율',
                text: 'Cake Defi는 다양한 수익 모델을 가지고 있으며, 모델마다 수익 비율이 다릅니다. 이를 통해 모델이 얼마나 차지하는지 명확하게 알 수 있습니다.',
                list: {
                    fencheng: "플랫폼 점유율",
                    zuchanzulin: "자산 임대",
                    daibishouyi: "토큰 수익",
                    zhekou: "NFT 할인 수익",
                    qita: "다른"
                }
            },
            yaoqing: {
                title: "커미션 초대",
                text: "플랫폼에는 더 많은 USDT를 얻을 수 있는 초대 시스템이 있으며, 추천인으로서 상당한 커미션을 받게 됩니다.",
                button: "지금 20USDT를 받으세요"
            },
            userchanliang: "사용자 소득",
            help: {
                title: "돕다",
                list: [
                    {
                        title: "Pancakeswap Cake DeFi는 어떻게 돈을 벌나요?",
                        text: `PoS 블록체인 네트워크에서 검증자는 보유하고 있는 암호화폐의 양과 담보로 "스테이킹"된 금액을 기반으로 새로운 블록을 생성하고 거래를 검증하도록 선택됩니다. 검증인이 더 많은 암호화폐를 보유할수록 거래를 검증하고 보상을 받을 수 있는 대상으로 선정될 확률이 높아집니다.`
                    },
                    {
                        title: "참여 조건은 무엇인가요?",
                        text: "각 참가자는 safepal, Trust, Tokenpocket, bitkeep 등 계약 협력 지갑을 보유해야 합니다. 참여하려면 참가자의 지갑에 BSC(BNB), ERC(ETH), TRC(TRX) 및 USDT(BSC, ERC & TRC)가 있어야 합니다. ."
                    },
                    {
                        title: "어떻게 참여하나요?",
                        text: "참가 자격을 얻기 위해서는 아주 적은 양의 활성화 가스를 지불한 후 가입을 완료해야 합니다. Cake DeFi는 참가자가 보유한 USDT에 따라 기본 수입을 분배합니다. USDT가 많을수록 더 높은 수익을 얻을 수 있습니다."
                    },
                    {
                        title: `"유연한" 스테이킹과 "고정 기간" 스테이킹의 차이점은 무엇인가요?`,
                        text: "두 가지 모두 USDT 토큰을 스테이킹하면 입금 수수료 없이 더 많은 USDT 토큰을 얻을 수 있습니다. 유연한 스테이킹을 통해 사용자는 USDT를 스테이킹하고 원할 때 언제든지 스테이킹을 해제할 수 있어 보상을 받을 수 있습니다. 고정 기간 스테이킹을 사용하면 사용자는 자신이 선택한 기간 동안 스테이킹된 USDT를 고정하여 수익률을 극대화하고 더 많은 USDT를 얻을 수 있으며 유연한 스테이킹에 비해 선형적으로 증가된 수익률을 얻을 수 있습니다. 유연한 스테이킹과 고정 기간 스테이킹은 모두 동일한 풀의 일부이므로 사용자는 두 가지 스테이킹 옵션 간에 쉽게 마이그레이션할 수 있습니다."
                    },
                    {
                        title: "친구를 어떻게 초대하나요?",
                        text: "친구에게 개인 추천 링크를 보내세요. 홈페이지 왼쪽 상단에 있는 초대 버튼을 누르시면 됩니다."
                    },
                    {
                        title: "PoS 이용 약관:",
                        text: `당사 플랫폼을 사용함으로써 귀하는 여기에 명시된 이용약관에 동의하게 됩니다.<br><br>1. PoS Vol은 사용자에게 암호화폐 투자에 대한 이자를 얻을 수 있는 기회를 제공하는 스테이킹 풀 서비스입니다. 플랫폼의 보안과 무결성을 보장하기 위해 일정 금액의 이자를 얻은 사용자에게 감사 수수료가 부과됩니다.<br><br>2. 감사 수수료는 사용자와 하위 라인이 얻은 이자를 포함하여 투자된 총 금액을 기준으로 계산됩니다. 정기적인 감사를 수행하고 플랫폼의 안전과 안정성을 보장하는 비용을 충당하기 위해 수수료가 필요합니다.<br><br>3. 사용자가 일정 금액의 이자를 획득하고 이를 PoS Vol 계정에서 인출하면 인출된 금액에 감사 수수료가 부과됩니다. 이 수수료는 면제되거나 환불될 수 없습니다.<br><br>4. 사용자는 자신의 수입과 계정과 관련된 감사 수수료를 추적하는 것이 좋습니다. 감사 수수료를 지불하지 않으면 계정이 정지되거나 해지될 수 있습니다.<br><br>5. 당사는 사전 통지 없이 언제든지 본 이용 약관을 수정하거나 업데이트할 권리를 보유합니다. 규정 준수 여부를 확인하기 위해 본 이용 약관을 정기적으로 검토하는 것은 사용자의 책임입니다.<br><br>6. 당사 플랫폼을 사용함으로써 귀하는 본 이용 약관은 물론 개인 정보 보호 정책 및 기타 사항을 인정하고 동의하게 됩니다. 당사 웹사이트에 게시된 정책이나 지침.`
                    }
                ]
            },
            shengjibaodao: {
                title: "감사 보고서",
                text: "우리는 안전한 감사 보고서를 가지고 있습니다"
            },

            banquan: "© Pancakeswap & Cake DeFi 2023 판권 소유",

            award_forme: {
                title: "즉시 USDT 보상을 공유하고 받으세요",
                leiji: "누적",
                huode: "오다",
                msg: "친구를 초대하여 참여하세요. 친구가 다음 조건을 충족하면 동등한 USDT 보상을 받게 됩니다.",
            }
        },

        team: {
            team_title: "길드를 선택하세요",
            tian: "낮",
            shouyilv: "수익률",
            canyujinge: "참여금액",
            button: "접합 점",
            info: "길드 세부정보",
            qianbao: "지갑 계정 잔액",
            zhouqi: "주기",
            meiri: "일일 요금",
            shoyi: "실시간 수입",
            canyu: "투자에 참여하세요",
            guizhe: "길드 규칙",
            fangshi: "정산방법",
            fangshi_text: "배당금은 매일 지급되며, 만기 시 원금이 반환됩니다.",
            meirihuibao: "일일 수익률",
            touzizhouqi: "투자주기",
            yujishouyi: "예상 수입",
            zongshouru: "총 수익",
            teamjieshou: "길드 소개",
            yicanyu: "이미 참여했습니다"
        },
        fenxiang: {
            title: "친구들과 공유",
            text: "초대 링크를 보내고, 친구가 링크를 통해 노드에 가입하면 20 USDT, 최대 1500 USDT 및 커미션 보상을 받을 수 있으며, 친구가 친구를 노드에 가입하도록 초대하면 넉넉한 커미션 보상을 받을 수 있습니다!",
            fuzhi: "복사",
            tuandui: {
                title: "팀 정보",
                shuliang: "팀 수",
                yongjing: "팀 커미션",
                zuoriyongjing: "어제 커미션",
                xiayiji: "수준 1",
                xiaerji: "수준 2",
                xiangqing: "팀 세부정보",
            },
            biaoge: {
                title: "보상 공유",
                dengji: '수준',
                qujian: '간격',
                fanli: '할인'
            }
        },
        user: {
            qianbao: "지갑 잔액",
            pos: "PoS 볼륨",
            canyu: "전체 참여",
            zongchanliang: "총 생산량",
            zuorishouyi: "어제 출력",
            ketixian: "인출 가능",
            tixian: "철회하다",
            zongyue: "전체 균형",
            tixiancishu: "일일 인출",
            queren: "확인하다",
            chanliang: "생산하다",
            jiangli: "보상",
            zhiya: "저당",
            shijian: "시간",
            shuliang: "수량",
            zhuangtai: "조건",
            nodata: "데이터 없음",
            zhuangtai_yes: "네",
            zhuangtai_no: "아니",
            zhuangtai_load: "신청",
            shouxufei: "출금 수수료는 1.5 USDT입니다."
        },
        teampage: {
            title: "팀 세부정보",
            xiayiji: "수준 1",
            xiaerji: "수준 2",
            dizhi: "주소",
            yongjing: "수수료",
            riqi: "날짜 선택",
            jr: "오늘",
            zr: "어제",
            sy: "지난 달",
            by: "이번 달"
        },
        kefu:{
            title:"온라인 서비스",
            hello:"안녕하세요!",
            text:"현재 온라인 고객센터가 바쁜 관계로 텔레그램 고객센터로 연락주시면 상담이 가능합니다!",
            tghao:"@pandefi"
        }
    },
}