export default {
    tabbar: {
        home: "首頁",
        union: "公會",
        team: "團隊",
        user: "我的"
    },
    conn: {
        queding: "確定",
        quxiao: "取消",
        yicanyu: "已參與",
        copytext: "您的推广链接复制成功",
        tgcopytext: "Telegram 官方客服帳號複製成功，請在TelegramAPP與客服聯繫"
    },
    msg: {
        qingqiunoguanbi:"請求尚未完成，無法關閉頁面！",
        wangluoyouwu:"網絡有誤，請聯系在線客服！",
        canyushibai:"參與失敗，請聯系客服！",
        jiesuo:"請先解鎖波場錢包",
        jujuelianjie:"您拒絕了連接錢包",
        tibiyongwan:"今日提幣次數已用完",
        zuiditibi:"最低提款5USDT！",
        meiyouusdt:"當前賬戶沒有USDT可提現"
    },
    text: {
        home: {
            jianjie:"介紹我們最新的獎金活動:通過我們新推出的賭註增加和招募獎金計劃，您將獲得高達15,000 USDT獎金。",
            lianjieqianbao: "連接您的錢包",
            jiedianxuanze: "選擇網路節點連接錢包",
            jiedianxuanzequxiao: "取消",
            tuichumsg: "您已連結錢包，是否確定斷開連線！",
            queding: "確定",
            mashangjiaru: "馬上加入",
            lingqu: "領取",
            zhiyamoshi: "質押盈利模式",
            genduo: "更多",
            zhiyamoshimiaoshu: `1：將您的錢包連接到 Liquid Staking DApp
        <br>2：啟動並批准質押合約。
        <br>3：接收Staking衍生品
        <br>4：您的代幣根據協議的內置機制存放在驗證器中
        <br>5：您的質押衍生品開始累積獎勵
        <br>6：你可以在DeFi應用中使用這個Staking Derivative`,
            zongchanliang: '總產量',
            canyuzhe: '參與者',
            dangqiansuanli: '當前算力',
            yonghushouyi: '用戶收益',
            diyibu: {
                title: "第一步：連接錢包",
                text: "根據您的USDT餘額賺取第一步收益"
            },
            dierbu: {
                title: "第二步：加入 Staking",
                text: "接收 Staking 衍生品"
            },
            disanbu: {
                title: "第三步：收入接收",
                text: "您的 Staking Derivative 開始累積獎勵"
            },
            disinbu: {
                title: "第四步：分享你的專屬鏈接",
                text: "您的下屬將繼續為您賺取更多的USDT"
            },
            shouyilv: {
                title: '收益率',
                dengji: '等級',
                qujian: '區間',
                fanli: '返利'
            },
            yinglimoshibili: {
                title: '盈利模式比例',
                text: 'Cake Defi盈利模式多元化，不同模式盈利比例不同。通過這個，你可以清楚地知道模型佔了多少。',
                list: {
                    fencheng: "平台分成",
                    zuchanzulin: "資產租賃",
                    daibishouyi: "代幣收益",
                    zhekou: "折扣",
                    qita: "其他"
                }
            },
            yaoqing: {
                title: "邀請佣金",
                text: "平台有一個邀請系統來賺取更多的USDT，作為推薦人，您將收穫不菲的佣金。",
                button: "立即领取20USDT"
            },
            userchanliang: "用戶產量",
            help: {
                title: "幫助中心",
                list: [
                    {
                        title: "Pancakeswap Cake DeFi 是如何盈利的？",
                        text: "在 PoS 區塊鍊網絡中，選擇驗證器來創建新塊並根據他們持有的加密貨幣數量和“抵押”作為抵押品來驗證交易。驗證者投入的加密貨幣越多，他們被選中驗證交易並獲得獎勵的機會就越高。"
                    },
                    {
                        title: "參與條件是什麼？",
                        text: "每位參與者必須持有一個協議合作錢包：safepal、Trust、Tokenpocket、bitkeep等。參與者的錢包需要有BSC(BNB)、ERC(ETH)、TRC(TRX)&USDT(BSC、ERC&TRC)才能參與."
                    },
                    {
                        title: "我如何參與？",
                        text: "加入獲得參與資格需要在支付極少量的激活氣體後完成。 Cake DeFi 將根據參與者持有的 USDT 來分配原始收益。 USDT越多，獲得的收益比例就越高。"
                    },
                    {
                        title: "“靈活”和“固定期限”質押有什麼區別？",
                        text: "使用這兩種方式，您只需質押您的 USDT 代幣即可賺取更多 USDT 代幣，而無需支付存款費用。靈活的質押允許用戶質押 USDT 並獲得獎勵，並且可以隨時取消質押。定期質押允許用戶通過在他們選擇的一段時間內鎖定他們質押的 USDT 來最大化他們的收益並賺取更多的 USDT，與靈活質押相比獲得線性提升的收益。靈活質押和定期質押都是同一個池的一部分，允許用戶在兩種質押選項之間輕鬆遷移。"
                    },
                    {
                        title: "如何邀請好友？",
                        text: "將您的個人推薦鏈接發送給您的朋友。您可以點擊網站左上角的邀請按鈕."
                    },
                    {
                        title: "PoS 條款和條件：",
                        text: `使用我們的平台，即表示您同意此處規定的條款和條件。
                        <br>
                        <br>1. PoS Vol 是一項權益池服務，為用戶提供從其加密貨幣投資中賺取利息的機會。 為確保我們平台的安全性和完整性，對賺取一定利息的用戶收取審計費。
                        <br>
                        <br>2. 審核費用是根據用戶及其下線的投資總額計算，包括利息。 該費用是支付定期審計和確保我們平台的安全性和穩定性的費用所必需的。
                        <br>
                        <br>3. 用戶在PoS Vol賬戶中賺取一定利息後，提取的利息將收取審計費用。 此費用不能免除或退還。
                        <br>
                        <br>4.建議用戶跟踪他們的收入和與他們賬戶相關的審計費用。 未能支付審計費可能導致賬戶暫停或終止。
                        <br>
                        <br>5. 我們保留隨時修改或更新這些條款和條件的權利，恕不另行通知。 用戶有責任定期查看這些條款和條件以確保合規。
                        <br>
                        <br>6.通過使用我們的平台，您承認並同意這些條款和條件，以及我們的隱私政策和我們網站上發布的任何其他政策或指南.`
                    }
                ]
            },
            shengjibaodao: {
                title: "審計報告",
                text: "我們有安全的審計報告"
            },

            banquan: "© Pancakeswap & Cake DeFi 2023 保留所有權利",

            award_forme:{
                title:"分享立即獲得USDT獎勵",
                leiji:"累計",
                huode:"獲得",
                msg:"邀請好友,邀請好友加入，好友滿足以下條件，您就能獲得對等的USDT獎勵",
            }

        },

        team: {
            team_title: "選擇公會",
            tian: "天",
            shouyilv: "收益率",
            canyujinge: "參與金額",
            button: "我要參與",
            info: "公會詳情",
            qianbao: "錢包賬戶餘額",
            zhouqi: "週期",
            meiri: "每日利率",
            shoyi: "實時收益",
            canyu: "參與投資",
            guizhe: "公會規則",
            fangshi: "回款方式",
            fangshi_text: "每天派息，到期退還本金",
            meirihuibao: "每日回報率",
            touzizhouqi: "投資週期",
            yujishouyi: "預計收益",
            zongshouru: "總收入",
            teamjieshou: "公會介紹",
            yicanyu: "已參與"
        },
        fenxiang: {
            title: "分享朋友",
            text: "發送您的邀請鏈接，好友通過您的鏈接加入節點，你可以獲得20USDT，最高1500USDT和傭金獎勵，或者好友再邀請他的好友加入節點，您都將獲得豐厚的傭金獎勵！",
            fuzhi: "複製",
            tuandui: {
                title: "團隊資料",
                shuliang: "團隊數量",
                yongjing: "團隊佣金",
                zuoriyongjing: "昨日佣金",
                xiayiji: "下一級",
                xiaerji: "下二級",
                xiangqing: "團隊詳情",
            },
            biaoge: {
                title: "分享獎勵",
                dengji: '等級',
                qujian: '區間',
                fanli: '返利'
            }
        },
        user: {
            qianbao: "錢包餘額",
            pos: "PoS 卷",
            canyu: "參與總額",
            zongchanliang: "總產量",
            zuorishouyi: "昨日收益",
            ketixian: "可提現",
            tixian: "提現",
            zongyue: "總餘額",
            tixiancishu: "每日提現次數",
            queren: "確認",
            chanliang: "產量",
            jiangli: "獎勵",
            zhiya: "質押",
            shijian: "時間",
            shuliang: "數量",
            zhuangtai: "狀態",
            nodata: "沒有數據",
            zhuangtai_yes:"成功",
            zhuangtai_no:"失败",
            zhuangtai_load:"申请中",
            shouxufei:"提现手续费为 1.5 USDT"
        },
        teampage: {
            title: "團隊詳情",
            xiayiji: "下一級",
            xiaerji: "下二級",
            dizhi: "地址",
            yongjing: "傭金",
            riqi:"選擇日期",
            jr: "今日",
            zr: "昨日",
            sy: "上月",
            by: "本月",
        },
        kefu:{
            title:"網路客服",
            hello:"您好！",
            text:"由於目前線上客服繁忙，請聯絡我們Telegram客服諮詢！"
        }
    }
}