export default {
    tabbar: {
        home: "Lar",
        union: "Guilda",
        team: "Equipe",
        user: "Meu"
    },
    conn: {
        queding: "confirme",
        quxiao: "Cancelar",
        yicanyu: "Já participou",
        copytext: "O link da sua promoção foi copiado com sucesso.",
        tgcopytext: "A conta oficial de atendimento ao cliente do Telegram foi copiada com sucesso, entre em contato com o atendimento ao cliente no Telegram APP",
    },
    msg: {
        qingqiunoguanbi: "A solicitação não foi concluída. Não é possível fechar a página!",
        wangluoyouwu: "Erro de rede, entre em contato com o atendimento ao cliente online!",
        tibiyongwan: "O número de retiradas de moedas hoje se esgotou.",
        zuiditibi: "A retirada mínima é de 5USDT!",
        meiyouusdt: "Não há USDT para retirar da conta corrente.",
        canyushibai:"A participação falhou, entre em contato com o atendimento ao cliente!",
        jiesuo:"Por favor, desbloqueie a carteira Wave Field primeiro.",
        jujuelianjie:"Você se recusou a conectar a carteira."
    },
    text: {
        home: {
            jianjie:"Apresentando nosso último evento de bônus: Bônus de até 15.000 USDT para serem adquiridos em nossos novos programas lançados de aumento de staking e bônus de recrutamento.",
            lianjieqianbao: "Conecte sua carteira",
            jiedianxuanze: "Selecione o nó da rede para conectar-se à carteira",
            jiedianxuanzequxiao: "Cancelar",
            tuichumsg: "Você vinculou sua carteira, tem certeza de que deseja desconectar?",
            queding: "confirme",
            mashangjiaru: "Entrar",
            lingqu: "Aplicar",
            zhiyamoshi: "Modelo de Stake Profit",
            genduo: "Mais",
            zhiyamoshimiaoshu: `1：Conecte sua carteira ao Liquid Stake DApp
            <br>2：Ativar e aprovar o contrato de piquetagem。
            <br>3: Receber derivativos penhorados
            <br>4：Seus tokens são armazenados em validadores de acordo com os mecanismos integrados do protocolo
            <br>5:Seus derivativos de apostas começam a acumular recompensas
            <br>6：Você pode usar este derivado de Stake em aplicativos DeFi`,
            zongchanliang: 'Produção total',
            canyuzhe: 'Participante',
            dangqiansuanli: 'Poder de computação atual',
            yonghushouyi: 'Renda do usuário',
            diyibu: {
                title: "Etapa 1: conectar a carteira",
                text: "Ganhe a primeira etapa de ganhos com base no seu saldo em USDT"
            },
            dierbu: {
                title: "Passo 2: Junte-se ao compromisso",
                text: "Receba derivativos penhorados"
            },
            disanbu: {
                title: "Etapa 3: Receba recompensas",
                text: "Seus derivativos de apostas começam a acumular recompensas"
            },
            disinbu: {
                title: "Etapa 4: compartilhe seu link exclusivo",
                text: "Seus subordinados continuarão ganhando mais USDT para você"
            },
            shouyilv: {
                title: 'Taxa de rendimento',
                dengji: 'Nível',
                qujian: 'Intervalo',
                fanli: 'Desconto'
            },
            yinglimoshibili: {
                title: 'Proporção do modelo de lucro',
                text: 'Cake Defi tem modelos de lucro diversificados e modelos diferentes têm taxas de lucro diferentes. Com isso, você pode saber claramente quanto o modelo representa.',
                list: {
                    fencheng: "Compartilhamento de plataforma",
                    zuchanzulin: "Locação de ativos",
                    daibishouyi: "Ganhos de tokens",
                    zhekou: "Receita de desconto NFT",
                    qita: "Outro"
                }
            },
            yaoqing: {
                title: "Convidar comissão",
                text: "A plataforma possui um sistema de convites para ganhar mais USDT e, como referenciador, você receberá uma comissão considerável.",
                button: "Obtenha 20USDT agora"
            },
            userchanliang: "Renda do usuário",
            help: {
                title: "Ajuda",
                list: [
                    {
                        title: "Como o Pancakeswap Cake DeFi ganha dinheiro?",
                        text: "Em uma rede blockchain PoS, os validadores são selecionados para criar novos blocos e validar transações com base na quantidade de criptomoeda que possuem e “apostar” como garantia. Quanto mais criptomoedas um validador apostar, maiores serão suas chances de ser selecionado para validar transações e receber recompensas."
                    },
                    {
                        title: "Quais são as condições de participação?",
                        text: "Cada participante deve possuir uma carteira de cooperação de acordo: safepal, Trust, Tokenpocket, bitkeep, etc. A carteira do participante precisa ter BSC (BNB), ERC (ETH), TRC (TRX) e USDT (BSC, ERC e TRC) para participar ."
                    },
                    {
                        title: "Como faço para participar?",
                        text: "A adesão para obter qualificações de participação deve ser concluída após o pagamento de um pequeno gás de ativação. Cake DeFi distribuirá a renda primária de acordo com o USDT detido pelos participantes. Quanto mais USDT, maior será a proporção de receita obtida."
                    },
                    {
                        title: "What’s the difference between “Flexible” and “Fixed-Term” staking?",
                        text: "With both, you can simply stake your USDT tokens to earn more USDT tokens with no deposit fees. Flexible staking allows users to stake USDT and earn rewards with the ability to unstake whenever they please. Fixed-term staking allows users to maximise their yield and earn even more USDT by locking their staked USDT for a period of time they choose, earning a linearly boosted yield compared to flexible staking. Flexible staking and fixed-term staking are both part of the same pool to allow users easy migration between the two staking options."
                    },
                    {
                        title: "Como faço para convidar amigos?",
                        text: "Envie seu link de indicação pessoal para seus amigos. Você pode pressionar o botão Convidar no canto superior esquerdo do site."
                    },
                    {
                        title: "Termos e Condições do PoS:",
                        text: `Ao utilizar nossa plataforma, você concorda com os termos e condições aqui declarados.<br><br>1. PoS Vol é um serviço de pool de apostas que oferece aos usuários a oportunidade de ganhar juros sobre seus investimentos em criptomoedas. Para garantir a segurança e integridade de nossa plataforma, uma taxa de auditoria é cobrada dos usuários que ganharam um determinado valor de juros.<br><br>2. A taxa de auditoria é calculada com base no valor total investido, incluindo os juros auferidos por um usuário e sua linha descendente. A taxa é necessária para cobrir os custos de realização de auditorias regulares e garantir a segurança e estabilidade da nossa plataforma.<br><br>3. Depois que um usuário ganhar um determinado valor de juros e retirá-lo de sua conta PoS Vol, o valor retirado estará sujeito à taxa de auditoria. Esta taxa não pode ser dispensada ou reembolsada.<br><br>4. Os usuários são aconselhados a acompanhar seus ganhos e a taxa de auditoria associada à sua conta. O não pagamento da taxa de auditoria pode resultar na suspensão ou encerramento da conta.<br><br>5. Reservamo-nos o direito de modificar ou atualizar estes termos e condições a qualquer momento sem aviso prévio. É responsabilidade do usuário revisar regularmente estes termos e condições para garantir sua conformidade.<br><br>6.Ao utilizar nossa plataforma, você reconhece e concorda com estes termos e condições, bem como com nossa Política de Privacidade e quaisquer outras. políticas ou diretrizes publicadas em nosso site.`
                    }
                ]
            },
            shengjibaodao: {
                title: "Relatório de auditoria",
                text: "Temos relatórios de auditoria seguros"
            },

            banquan: "© Pancakeswap & Cake DeFi 2023 Todos os direitos reservados",
            
            award_forme:{
                title:"Compartilhe e receba recompensas em USDT instantaneamente",
                leiji:"cumulativo",
                huode:"pegar",
                msg:"Convide amigos para participar. Se seus amigos atenderem às seguintes condições, você receberá recompensas equivalentes em USDT.",
            }
        },

        team: {
            team_title: "Escolha uma guilda",
            tian: "dia",
            shouyilv: "Taxa de retorno",
            canyujinge: "Valor da participação",
            button: "juntar",
            info: "Detalhes da Guilda",
            qianbao: "Saldo da conta da carteira",
            zhouqi: "Ciclo",
            meiri: "Diária",
            shoyi: "Ganhos em tempo real",
            canyu: "Participe do investimento",
            guizhe: "Regras da guilda",
            fangshi: "Método de liquidação",
            fangshi_text: "Os dividendos são pagos diariamente e o principal é devolvido no vencimento",
            meirihuibao: "Taxa diária de retorno",
            touzizhouqi: "Ciclo de investimento",
            yujishouyi: "Renda estimada",
            zongshouru: "Rendimento total",
            teamjieshou: "Introdução à guilda",
            yicanyu: "Já participou"
        },
        fenxiang: {
            title: "Compartilhe com amigos",
            text: "Envie seu link de convite e, se seus amigos ingressarem no nó através do seu link, você poderá obter 20 USDT, até 1.500 USDT e recompensas de comissão, ou se seus amigos convidarem seus amigos para ingressar no nó, você receberá generosas recompensas de comissão!",
            fuzhi: "cópia de",
            tuandui: {
                title: "Informações da equipe",
                shuliang: "Número de equipes",
                yongjing: "Comissão de equipe",
                zuoriyongjing: "Comissão de ontem",
                xiayiji: "nível 1",
                xiaerji: "nível 2",
                xiangqing: "Detalhes da equipe",
            },
            biaoge: {
                title: "Compartilhe recompensas",
                dengji: 'nível',
                qujian: 'Intervalo',
                fanli: 'Desconto'
            }
        },
        user: {
            qianbao: "Saldo da carteira",
            pos: "PoS volume",
            canyu: "Participação total",
            zongchanliang: "Produção total",
            zuorishouyi: "Saída de ontem",
            ketixian: "Retirável",
            tixian: "Retirar",
            zongyue: "Balanço total",
            tixiancishu: "Retiradas diárias",
            queren: "confirme",
            chanliang: "Colheita",
            jiangli: "Recompensa",
            zhiya: "Hipoteca",
            shijian: "Tempo",
            shuliang: "Quantidade",
            zhuangtai: "doença",
            nodata: "Sem dados",
            zhuangtai_yes: "sim",
            zhuangtai_no: "não",
            zhuangtai_load: "Aplicação em",
            shouxufei:"A taxa de retirada é de 1,5 USDT"
        },
        teampage: {
            title: "Detalhes da equipe",
            xiayiji: "nível 1",
            xiaerji: "nível 2",
            dizhi: "Endereço",
            yongjing: "Comissão",
            riqi:"Selecione a data",
            jr: "Hoje",
            zr: "Ontem",
            sy: "Mês passado",
            by: "Este mês"
        },
        kefu:{
            title:"serviço on-line",
            hello:"Olá!",
            text:"Como o atendimento ao cliente online está ocupado no momento, entre em contato com nosso atendimento ao cliente Telegram para consulta!",
            tghao:"@pandefi"
        }
    },
}