import { createI18n } from 'vue-i18n'

import zhCHT from './lang/zh-CHT'
import enUS from './lang/en-US'
import enID from './lang/en-ID'
import jaJP from './lang/ja-JP'
import koKR from './lang/ko-KR'
import viVN from './lang/vi-VN'
import ptBR from './lang/pt-BR'
import thth from './lang/th-th'
import deDE from './lang/de-DE'
import ES from './lang/ES'

const messages = {
    'en-US': enUS,
    'en-ID': enID,
    'ja-JP': jaJP,
    'ko-KR': koKR,
    'vi-VN': viVN,
    'pt-BR': ptBR,
    'th-th': thth,
    'de-DE': deDE,
    'ES': ES,
    'zh-CHT': zhCHT
}

var lang_key = window.localStorage.getItem('lang');

const i18n = createI18n({
    locale: lang_key ? lang_key:'en-US',
    fallbackLocale: "en-US",
    messages
})

export default i18n;